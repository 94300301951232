<template>
  <div class="choose-role">
    <div class="choose-role-wrap">
      <img src="@/assets/img/icon-VB-flat.png" alt="" />

      <div class="bottom-btn">
        <p>{{ $t("lang.ChooseRole") }}</p>
        <mt-button size="large" @click="employee">{{
          $t("lang.Employee")
        }}</mt-button>
        <mt-button size="large" @click="fleetAdmin">{{
          $t("lang.FleetAdmin")
        }}</mt-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { setCookie } from "../../utils/CookieManager";

export default {
  data() {
    return {
      isRefresh: true,
    };
  },
  created() {
    this.$store.commit("updateCarInfo", {
      isRefresh: this.isRefresh,
    });
  },
  methods: {
    async fetchTicket() {
      return "pUzGYS0KOpM6MSN7sQveig7-9_s_KeMweXJXs0bbWlI";
      let response = await this.$api.vehicle.fetchTicket();
      if (response) {
        return response.ticket.split("JST")[1];
      } else {
        return "";
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.path == "/admin-vehicle-list") {
        to.meta.keepAlive = false;
      }
      next();
    },
    async toGetSignature(url) {
      let timestamp = new Date().valueOf();
      let nonceStr = this.randomString(16);
      let response = await this.$api.vehicle.toGetSignature(
        timestamp,
        nonceStr,
        url
      );
      if (response) {
        return {
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: response,
        };
      } else {
        return {};
      }
    },
    randomString(e) {
      e = e || 32;
      let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    async submit(loginId,  userRole) {
      let response = await Vue.prototype.$api.login.userLogin({
        loginId: loginId,
        role: userRole,
      });
      if (response.token) {
        this.$store.state.information = false;
        setCookie("vb_token", response.token);
        if (userRole !== "Employee") {
          this.$router.push({
            path: "/admin-vehicle-list",
            name: "adminVehicleList",
            params: { isUpdate: true },
          });
        } else {
          this.$router.push("/vehicle-list");
        }
      }
    },
    employee() {
      window.localStorage.setItem("empIdIsEmployee", true);
      let userDatas = JSON.parse(window.localStorage.getItem("userRole"));
      let user = {};
      userDatas.forEach(function (element) {
        if (element.role === "Employee") {
          user = element;
        }
      });
      this.submit(user.loginId, user.role);
    },
    fleetAdmin() {
      window.localStorage.setItem("empIdIsEmployee", false);
      let userDatas = JSON.parse(window.localStorage.getItem("userRole"));
      let user = {};
      userDatas.forEach(function (element) {
        if (element.role !== "Employee") {
          user = element;
        }
      });
      this.submit(user.loginId, user.role);
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.choose-role {
  height: 100vh;
  background: url("../../assets/img/mercedes-benz-international-corporate-sales.png")
    no-repeat;
  background-size: auto 100%;

  .choose-role-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    img {
      padding-top: 25%;
    }
    .bottom-btn {
      position: absolute;
      bottom: 5%;
      p {
        color: #ffffff;
        font-size: 0.16rem;
        display: flex;
        justify-content: center;
        padding-bottom: 0.24rem;
      }
      .mint-button {
        width: 2.75rem;
        margin: 0rem 0.2rem 0.24rem 0.2rem;
        background-color: #4c9edd;
        color: #ffffff;
        font-size: 0.16rem;
      }
    }
  }
}
</style>
