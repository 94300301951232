import * as imageConversion from 'image-conversion';

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  if (!filename) {
    filename = Date.parse(new Date()) + ".jpg";
  }
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function compressAccurately(file) {
  return new Promise((resolve, reject) => {
    imageConversion.compressAccurately(file, 500).then(res => {
      const afterFile = new File([res], file.name, { type: res.type, lastModified: Date.now() })
      resolve(afterFile);
    })
  })
}

