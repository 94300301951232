<template>
  <div class="car-plate-detail">
    <div class="input-car-plate-title-wrap">
      <div class="input-car-plate-title blod-font">
        {{ $t("lang.InputCarPlateInformation") }}
      </div>
      <div class="switch-wrap">
        <mt-switch v-model="switchChoose"></mt-switch>
        <div class="newEnergy-vehicle-title">
          {{ $t("lang.NewEnergyVehicle") }}
        </div>
      </div>
    </div>
    <div class="nput-car-plate">
      <div class="license-plate-number">{{ $t("lang.CarPlateNo") }}</div>
      <div class="plateRegion">{{ this.plateProvinces }}</div>
      <img
        src="@/assets/img/icon-arrowdown.png"
        alt=""
        @click="choosePlateRegion"
      />
      <input
        type="text"
        name=""
        id="input-plate-num"
        onkeyup="toUpperCase(this)"
        @onKeyUp="value = value.replace(/[^A-Za-z0-7]/g, '')"
        v-model="plateNumber"
        :maxlength="this.num"
      />
    </div>

    <div class="stock-taking-next-btn">
      <mt-button
        size="large"
        class="stock-taking-btn"
        :disabled="this.disabled"
        :class="toggleType"
        @click="goToNext"
        >{{ $t("lang.Next") }}</mt-button
      >
    </div>
    <div class="popUpPlateRegion">
      <mt-popup v-model="popupVisible" position="bottom">
        <div>
          <div
            class="list"
            v-for="(item, index) in this.regionData"
            :key="index"
            @click="chooseProvince(item)"
          >
            {{ item }}
          </div>
          <div class="cancelBtn">
            <mt-button type="primary" size="small" @click="closeOnClickModal">{{
              $t("lang.Cancel")
            }}</mt-button>
          </div>
        </div>
      </mt-popup>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      codeUrl: "",
      codeUrl2: "",
      popupVisible: false,
      plateProvinces: "京",
      switchChoose: false,
      plateNumber: "",
      originalData: "",
      disabled: true,
      Zhanwei: "",
      num: 6,
      language: "",
      checkResult: [],
      string: "",
      code: "",
      regionData: [
        "京",
        "津",
        "冀",
        "晋",
        "蒙",
        "辽",
        "吉",
        "黑",
        "沪",
        "苏",
        "浙",
        "皖",
        "闽",
        "赣",
        "鲁",
        "豫",
        "鄂",
        "湘",
        "粤",
        "桂",
        "琼",
        "渝",
        "川",
        "贵",
        "云",
        "藏",
        "陕",
        "甘",
        "青",
        "宁",
        "新",
      ],
    };
  },
  created() {
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
              Object.assign(
                      {},
                      this.$store.state,
                      JSON.parse(sessionStorage.getItem('store'))
              )
      )
    }
    if (this.$store.state.scanSuccess) {
      Toast({
        message: this.$t("lang.ScanSuccess"),
        duration: 3000,
        className: "ToastTop",
      });
      this.$store.state.scanSuccess = false;
    }
    this.language = navigator.language;
    this.string = this.$store.state.plateNumber;
    var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    if (reg.test(this.string)) {
      this.plateProvinces = this.string.substring(0, 1);
      this.string = this.string.slice(1);
    }
    if (this.string.length > 6) {
      this.switchChoose = true;
    } else {
      this.switchChoose = false;
    }
    this.plateNumber = this.string;
    if (
      window.location.href.includes("/car-plate") ||
      window.location.href.includes("/car-vin")
    ) {
      this.$store.commit("updateCarInfo", {
        carBodyPhoto: "",
        interiorPhoto: "",
        warningTriangleInCar: "",
        fireExtinguisher: "",
        floorMat: "",
        mileage: "",
        damages: "",
        carIsMobileAndInUse: "",
        result: "",
        leftFrontResult: "",
        rightBackResult: "",
        exteriorPhoto: "",
        photoList: "",
        latitude: "",
        longitude: "",
        damageImgList: [],
        choosePerfect: "",
        chooseMinor: "",
        chooseMedium: "",
        chooseMajor: "",
      });
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  beforeDestroy() {
    this.switchChoose = false;
    this.plateNumber = "";
  },
  computed: {
    toggleType() {
      if (this.plateNumber.length > 5) {
        if (this.switchChoose == false) {
          this.disabled = false;
          return "verifyBtn";
        } else {
          if (this.plateNumber.length > 6) {
            this.disabled = false;
            return "verifyBtn";
          }
        }
      }
    },
  },
  watch: {
    plateNumber() {
      this.plateNumber = this.plateNumber.toUpperCase();
      if (this.switchChoose == false && this.plateNumber.length > 6) {
        this.plateNumber = this.plateNumber.substring(0, 6);
      } else if (this.switchChoose == true && this.plateNumber.length > 7) {
        this.plateNumber = this.plateNumber.substring(0, 7);
      }
    },
    switchChoose(value) {
      if (value == false) {
        this.num = 6;
        if (this.plateNumber.length == 7) {
          this.plateNumber = this.plateNumber.substring(0, 6);
        }
      } else {
        this.num = 7;
      }
    },
  },
  methods: {
    beforeRouteLeave(to, from, next) {
      if (to.path == "/stockTakingOne") {
        to.meta.keepAlive = false;
      }
      next();
    },
    choosePlateRegion() {
      this.popupVisible = true;
    },
    show() {
      Toast({
        message: this.$t("lang.CheckPlateNumber"),
        position: "top",
        duration: 1000,
      });
    },
    toUpperCase(obj) {
      obj.value = obj.value.toUpperCase();
    },
    closeOnClickModal() {
      this.popupVisible = false;
    },
    chooseProvince(item) {
      this.plateProvinces = item;
      this.popupVisible = false;
    },
    async searchList(key) {
      let response = await this.$api.vehicle.searchVehicleList(key);
      if (response) {
        this.checkResult = response;
        if (this.checkResult[0]) {
          if (this.checkResult[0].legacyPlateNo == key) {
            if (this.$store.state.plateNumber) {
              if (this.$store.state.plateNumber.includes(key)) {
                this.$store.commit("updateCarInfo", {
                  carModel: this.checkResult[0].model,
                  plateNumber: this.plateProvinces + this.plateNumber,
                  VIN: this.checkResult[0].vin,
                  id: this.checkResult[0].id,
                });
                if (this.checkResult[0].stockStatus !== 'TASK') {
                  this.$router.push(`/vehicle-information/${this.checkResult[0].id}`);
                } else {
                   sessionStorage.setItem('store', JSON.stringify(this.$store.state))
                  this.$router.push("/stockTakingOne");
                }
              } else {
                this.show();
              }
            } else {
              this.$store.commit("updateCarInfo", {
                carModel: this.checkResult[0].model,
                plateNumber: this.plateProvinces + this.plateNumber,
                VIN: this.checkResult[0].vin,
                id: this.checkResult[0].id,
              });
              if (this.checkResult[0].stockStatus !== 'TASK') {
                this.$router.push(`/vehicle-information/${this.checkResult[0].id}`);
              } else {
                sessionStorage.setItem('store', JSON.stringify(this.$store.state))
                this.$router.push("/stockTakingOne");
              }
            }
          } else {
            this.show();
          }
        } else {
          this.show();
        }
      }
    },
    goToNext() {
      this.searchList(this.plateNumber);
    },
  },
};
</script>
<style lang="scss" scoped>
.car-plate-detail {
  background: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  .input-car-plate-title-wrap {
    .input-car-plate-title {
      font-size: 0.13rem;
      line-height: 0.18rem;
      color: #333333;
      padding-top: 0.24rem;
      padding-bottom: 0.24rem;
      height: 0.18;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      font-weight: 600;
    }

    .switch-wrap {
      display: flex;
      flex-direction: row-reverse;
      padding-bottom: 0.13rem;
      .newEnergy-vehicle-title {
        font-size: 0.13rem;
        line-height: 0.18rem;
        color: #333333;
      }
      .mint-switch {
        padding-left: 0.08rem;
        padding-right: 0.2rem;
      }
    }
  }
  .nput-car-plate {
    width: 100%;
    height: 0.52rem;
    border-bottom: solid 0.01rem #ebebeb;
    border-top: solid 0.01rem #ebebeb;
    display: flex;
    align-items: center;
    .license-plate-number {
      width: 1.12rem;
      height: 0.2rem;
      font-size: 0.14rem;
      color: #333333;
      line-height: 0.2rem;
      border-right: solid 0.01rem #ebebeb;
      display: flex;
      justify-content: center;
      margin-right: 0.2rem;
    }
    #input-plate-num {
      border-width: 0;
      outline: none;
      margin-left: 0.14rem;
    }
    .plateRegion {
      font-size: 0.14rem;
      color: #4c9edd;
      margin-right: 0.09rem;
    }
  }
}
.popUpPlateRegion {
  background-color: #f6f6f6;
  .mint-popup-bottom {
    width: 100%;
    height: 3.93rem;
    overflow: scroll;
    font-size: 0.16rem;
    line-height: 0.22rem;
    display: flex;
    justify-content: center;
    background-color: #ffffff;

    .list {
      width: 100%;
      display: flex;
      align-items: center;
      list-style-type: none;
      justify-content: center;
      height: 0.39rem;
      border-bottom: solid 0.01rem #f6f6f6;
      color: #4c9edd;
      font-family: PingFangSC;
    }
    .cancelBtn {
      width: 3.75rem;
      border-top: solid #f6f6f6 0.1rem;
      background-color: white;
      display: flex;
      justify-content: center;
      padding-top: 0.05rem;
      .mint-button--primary {
        background-color: white;
        color: #666666;
        font-size: 0.16rem;
        line-height: 0.2rem;
      }
    }
  }
}
.stock-taking-next-btn {
  padding-top: 0.32rem;
}
.mt-toast {
  background-color: #4c9edd !important;
}
.ToastTop {
  background-color: #4c9edd !important;
  height: 0.44rem;
  width: 3.75rem;
  font-family: PingFangSC;
  font-size: 0.14rem;
}
</style>
