import Vue from "vue";
import axios from "axios";
import router from "../../router";
import { getCookie, getEmployeeEmpId, setCookie } from "../../utils/CookieManager";
import { Indicator } from "mint-ui";


axios.defaults.timeout = 60 * 1000;

axios.defaults.withCredentials = true;
Vue.prototype.$static = "";
axios.interceptors.request.use(
  (config) => {
    if (getCookie("vb_token")) {
      config.headers["Authorization"] = "JWT " + getCookie("vb_token");
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["empid"] = getEmployeeEmpId();
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (res) => {
    if (res.status == 200) {
      return res;
    } else if (res.status == 401) {
      setCookie("weComCode", "", "365");
      setCookie("vb_token", "", "365");
      router.push("/");
    }
  },
  (err) => {
    alert("请求失败, 请稍后再试!");
    Indicator.close();
    return Promise.reject(err);
  }
);

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err.data);
        }
      )
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err.data);
        }
      )
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function cut(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err.data);
        }
      )
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function fetchImage(url, params) {
  return new Promise(function (resolve) {
    axios({
      method: "get",
      url: url,
      params: params,
      headers: {
        "Content-Type": "application/json",
        Accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9", // 必须添加的请求头
      },
    })
      .then(function (response) {
        return response.data;
      })
      .then((data) => {
        resolve(data);
      });
  }).catch(function (reason) {});
}

export function uploadImage(uri, file) {
  const fileData = new FormData();
  fileData.append("file", file);
  return new Promise(function () {
    axios.post(uri, fileData).then(function (response) {
      return response.data;
    });
  });
}
