<template>
  <div class="stock-taking-one">
    <ul class="info-list-wrap label-wrap">
      <li class="info-list label-info-height">
        {{ $t("lang.PlateNumber") }}
        <div class="userDetail">{{ this.plateNumber }}</div>
      </li>
      <li class="info-list label-info-height">
        {{ $t("lang.CarModel") }}
        <div class="userDetail">{{ this.carModel }}</div>
      </li>
      <li class="info-list label-info-height">
        {{ $t("lang.VIN") }}
        <div class="userDetail">{{ this.VIN }}</div>
      </li>
    </ul>
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information">
        <img src="@/assets/img/icon-car_front.png" alt="" />
        {{ $t("lang.VehicleBasicInformation") }}
      </div>
      <li class="info-list main-info-height">
        {{ $t("lang.Location") }}
        <div class="userDetail" @click="getLocation" v-show="latitude == ''">
          <img src="@/assets/img/icon-locationdefault.png" alt="" />
          {{ $t("lang.MyLocation") }}
        </div>
        <div class="userDetail" v-show="latitude != ''">
          <img src="@/assets/img/icon-location-has-done.png" alt="" />
          {{ $t("lang.MyLocationObtained") }}
        </div>
      </li>
      <!-- <li class="info-list main-info-height">
        {{ $t("lang.ExteriorColour") }}
        <div class="userDetail" @click="chooseExteriorColour">
          <p v-show="this.carBodyPhoto == ''">{{ $t("lang.Required") }}</p>
          <p v-show="this.carBodyPhoto != ''">{{ this.carBodyPhoto }}</p>
          <img src="@/assets/img/icon-arrowdown.png" alt="" />
        </div>
      </li> -->
      <!-- <li class="info-list main-info-height">
        {{ $t("lang.InteriorPhoto") }}
        <div class="userDetail" @click="chooseInteriorPhoto">
          <p v-show="this.interiorPhoto == ''">{{ $t("lang.Required") }}</p>
          <p v-show="this.interiorPhoto != ''">{{ this.interiorPhoto }}</p>

          <img src="@/assets/img/icon-arrowdown.png" alt="" />
        </div>
      </li> -->
      <li class="exteriorPhoto">
        {{ $t("lang.ExteriorPhoto") }}
        <p>{{ $t("lang.PleaseTakePhotos") }}</p>
        <div class="exterior-photo-wrap">
          <div class="photo-wrap">
            <div class="camera-wrap" @click="leftFrontResultClick">
              <img
                v-show="this.leftFrontResult == ''"
                src="@/assets/img/icon-camera.png"
                alt=""
              />
              <img
                class="delect-icon"
                v-show="this.leftFrontResult != ''"
                @click.stop="delectPhoto"
                src="@/assets/img/icon-cross.png"
                alt=""
              />
              <img
                class="wx-photo"
                v-if="this.leftFrontResult != ''"
                :src="this.leftFrontResult"
                alt=""
              />
            </div>

            <div class="camera-wrap" @click="rightBackResultClick">
              <img
                v-show="!rightBackResult"
                src="@/assets/img/icon-camera.png"
                alt=""
              />
              <img
                class="delect-icon"
                v-show="this.rightBackResult != ''"
                @click.stop="delectLeftFrontPhoto"
                src="@/assets/img/icon-cross.png"
                alt=""
              />
              <img
                class="wx-photo"
                v-show="rightBackResult"
                :src="rightBackResult"
                alt=""
              />
            </div>
          </div>
          <div class="photo-word">
            <p>{{ $t("lang.LeftFront") }}</p>
            <p>{{ $t("lang.RightBack") }}</p>
          </div>
        </div>
      </li>
    </ul>
    <ul class="info-list-wrap label-wrap">
      <div class="Vehicle-basic-information adjust-margin">
        <img src="@/assets/img/icon-Identity_Card.png" alt="" />
        {{ $t("lang.VehicleLicense") }}
      </div>
      <div class="vehicle-license-wrap">
        <div class="camera-wrap" @click="vehicleLicenseClick">
          <div class="camera-photo">
            <img v-show="!result" src="@/assets/img/icon-camera.png" alt="" />
            <img
              class="delect-result-vehicle-icon"
              v-show="this.result != ''"
              @click.stop="delectResultPhoto"
              src="@/assets/img/icon-cross.png"
              alt=""
            />
            <img class="wx-photo" v-show="result" :src="result" alt="" />
          </div>
          <p v-show="!result">{{ $t("lang.TapHereToAddPhoto") }}</p>
        </div>
      </div>
    </ul>
    <div class="stock-taking-next-btn">
      <mt-button
        size="large"
        class="stock-taking-btn"
        :disabled="this.disabled"
        :class="toggleType"
        @click="nextStep"
        >{{ $t("lang.Next") }}</mt-button
      >
    </div>

    <ChooseList
      :colorPopUp="colorPopUp"
      :dataList="colorDataList"
      @cancle="cancleBtn"
      @chooseItem="chooseItem"
    ></ChooseList>
  </div>
</template>
<script>
import ChooseList from "@/components/choose-list.vue";
import remoteLoad from "../../assets/remoteload";
export default {
  components: {
    ChooseList,
  },
  data() {
    return {
      carModel: "",
      plateNumber: "",
      VIN: "",
      interiorPhoto: this.$store.state.interiorPhoto,
      carBodyPhoto: this.$store.state.carBodyPhoto,
      colorPopUp: false,
      carBody: false,
      internalStyle: true,
      colorDataList: [],
      disabled: true,
      result: this.$store.state.result,
      leftFrontResult: this.$store.state.leftFrontResult,
      rightBackResult: this.$store.state.rightBackResult,
      latitude: this.$store.state.latitude,
      longitude: this.$store.state.longitude,
      returnStr: "",
    };
  },
  mounted() {
    this.toGetSignature(
      parseInt(Math.round(new Date().getTime() / 1000).toString()),
      this.randomString(16),
      encodeURIComponent(window.location.href.split("#")[0])
    );
  },
  computed: {
    toggleType() {
      if (
        // this.carBodyPhoto != "" &&
        // this.interiorPhoto != "" &&
        this.result &&
        this.leftFrontResult &&
        this.latitude &&
        this.rightBackResult
      ) {
        this.disabled = false;
        return "verifyBtn";
      }
    },
  },
  created() {
    // if (sessionStorage.getItem('store')) {
    //   this.$store.replaceState(
    //           Object.assign(
    //                   {},
    //                   this.$store.state,
    //                   JSON.parse(sessionStorage.getItem('store'))
    //           )
    //   )
    // }
    remoteLoad("https://res.wx.qq.com/open/js/jweixin-1.2.0.js");
    let url = window.location.href.split("#")[0];
    this.carModel = this.$store.state.carModel;
    this.VIN = this.$store.state.VIN;
    var plateStr = "";
    plateStr = this.$store.state.plateNumber;
    this.plateNumber = plateStr.slice(0, 2) + " " + plateStr.slice(2);
    if (!this.carModel) {
      this.$router.go(-1);
    }
  },

  methods: {
    chooseExteriorColour() {
      this.colorDataList = [
        this.$t("lang.White"),
        this.$t("lang.Silver"),
        this.$t("lang.Grey"),
        this.$t("lang.Blue"),
        this.$t("lang.Green"),
        this.$t("lang.Red"),
        this.$t("lang.Yellow"),
        this.$t("lang.Orange"),
        this.$t("lang.Black"),
        this.$t("lang.Brown"),
      ];
      this.colorPopUp = true;
      this.carBody = true;
      this.InternalStyle = false;
    },
    randomString(e) {
      e = e || 32;
      let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    async toGetSignature(timestamp, nonceStr, url) {
      let response = await this.$api.vehicle.toGetSignature(
        timestamp,
        nonceStr,
        url
      );
      if (response) {
        wx.config({
          beat: true,
          debug: false,
          appId: "ww755fa7ed1d1af941",
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: response,
          jsApiList: ["getLocation", "chooseImage"],
        });
      }
    },
    chooseInteriorPhoto() {
      this.colorDataList = [
        this.$t("lang.Grey"),
        this.$t("lang.Black"),
        this.$t("lang.Brown"),
        this.$t("lang.Beige"),
        this.$t("lang.Red"),
        this.$t("lang.Blue"),
      ];
      this.colorPopUp = true;
      this.InternalStyle = true;
      this.carBody = false;
    },
    cancleBtn(msg) {
      this.colorPopUp = msg;
    },
    chooseItem(item) {
      if (this.carBody == true) {
        this.carBodyPhoto = item;
      } else if (this.internalStyle == true) {
        this.interiorPhoto = item;
      }
    },
    getLocation() {
      var that = this;
      wx.getLocation({
        type: "wgs84",
        success: function (res) {
          that.latitude = res.latitude;
          that.longitude = res.longitude;
        },
        complete: function (res) {},
        fail: function (res) {},
      });
    },
    nextStep() {
      this.$store.commit("inventoryInformation", {
        carBodyPhoto: this.carBodyPhoto,
        interiorPhoto: this.interiorPhoto,
        result: this.result,
        leftFrontResult: this.leftFrontResult,
        rightBackResult: this.rightBackResult,
        latitude: this.latitude,
        longitude: this.longitude,
      });
      //  sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      this.$router.push("/stockTakingTwo");
    },
    delectPhoto() {
      this.leftFrontResult = "";
    },
    delectResultPhoto() {
      this.result = "";
    },
    delectLeftFrontPhoto() {
      this.rightBackResult = "";
    },
    vehicleLicenseClick() {
      let that = this;
      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["camera"],
        success(res) {
          wx.getLocalImgData({
            localId: res.localIds[0],
            success(ew) {
              if (ew.localData.indexOf("data:image") == 0) {
                that.result = ew.localData;
              } else {
                that.result =
                  "data:image/jpeg;base64," + ew.localData.replace(/\n/g, "");
              }
            },
          });
        },
      });
    },
    leftFrontResultClick() {
      if (this.leftFrontResult == "") {
        let that = this;
        wx.chooseImage({
          count: 1,
          sizeType: ["original", "compressed"],
          sourceType: ["camera"],
          success(res) {
            wx.getLocalImgData({
              localId: res.localIds[0],
              success(ew) {
                if (ew.localData.indexOf("data:image") == 0) {
                  that.leftFrontResult = ew.localData;
                } else {
                  that.leftFrontResult =
                    "data:image/jpeg;base64," + ew.localData.replace(/\n/g, "");
                }
              },
            });
          },
        });
      }
    },
    rightBackResultClick() {
      let that = this;
      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["camera"],
        success(res) {
          wx.getLocalImgData({
            localId: res.localIds[0],
            success(ew) {
              if (ew.localData.indexOf("data:image") == 0) {
                that.rightBackResult = ew.localData;
              } else {
                that.rightBackResult =
                  "data:image/jpeg;base64," + ew.localData.replace(/\n/g, "");
              }
            },
          });
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.stock-taking-one {
  height: 100vh;
  .info-list-wrap {
    margin-bottom: 0.1rem;
    .Vehicle-basic-information {
      height: 0.52rem;
      font-size: 0.16rem;
      line-height: 0.22rem;
      color: #333333;
      font-weight: 600;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #ebebeb;
      align-items: center;
      display: flex;
      padding-left: 0.2rem;
      img {
        padding-right: 0.08rem;
      }
    }
    .info-list {
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: #333333;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #f6f6f6;
      align-items: center;
      display: flex;
      justify-content: space-between;
      .userDetail {
        padding-right: 0.2rem;
        font-size: 0.14rem;
        color: #999999;
        line-height: 0.2rem;
        display: flex;
        align-content: center;
        img {
          padding-left: 0.1rem;
          display: block;
          margin: auto;
        }
      }
      .userDetailPhone {
        padding-right: 0.2rem;
        font-size: 0.14rem;
        text-decoration: none;
        line-height: 0.2rem;
      }
    }
    .label-info-height {
      padding: 0.08rem 0rem 0.08rem 0.2rem;
      border-bottom: 0rem;
    }
    .main-info-height {
      padding: 0.13rem 0rem 0.13rem 0.2rem;
    }
    .exteriorPhoto {
      padding: 0.13rem 0rem 0.13rem 0.2rem;
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: #333333;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #f6f6f6;
      justify-content: center;
      display: flex;
      flex-direction: column;
      p {
        font-size: 0.12rem;
        color: #999999;
        line-height: 0.17rem;
        padding-top: 0.03rem;
      }
      .photo-wrap {
        display: flex;
        .camera-wrap {
          width: 0.8rem;
          height: 0.8rem;
          border: dashed 0.01rem #d8d8d8;
          margin: 0.1rem 0.2rem 0rem 0rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img {
            width: 0.33rem;
            height: 0.29rem;
          }
          .wx-photo {
            width: 0.8rem;
            height: 0.8rem;
          }
          .camera-photo {
            position: relative;
            .delect-result-icon {
              position: absolute;
              z-index: 10000;
              width: 0.16rem;
              height: 0.16rem;
              top: 0.03rem;
              right: 0.03rem;
            }
          }
          .delect-icon {
            position: absolute;
            z-index: 1000;
            width: 0.16rem;
            height: 0.16rem;
            top: 0.03rem;
            right: 0.03rem;
          }
        }
      }
      .photo-word {
        display: flex;
        p {
          width: 0.82rem;
          padding-right: 0.2rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .label-wrap {
    padding: 0.1rem 0rem;
    background-color: #ffffff;
    position: relative;
    .vehicle-license-wrap {
      display: flex;
      justify-content: center;
      .camera-wrap {
        width: 3.35rem;
        height: 1.2rem;
        border: dashed 0.01rem #d8d8d8;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        margin-top: 0.1rem;
        img {
          padding-top: 0.37rem;
        }
        .wx-photo {
          width: 3.35rem;
          position: absolute;
          top: -0.37rem;
          left: 0rem;
          transform: translate(0%, -20%);
        }

        p {
          font-size: 0.12rem;
          color: #999999;
          line-height: 0.17rem;
          padding-top: 0.03rem;
        }
      }
      .delect-result-vehicle-icon {
        padding-top: 0rem !important;
        position: absolute;
        width: 0.16rem;
        height: 0.16rem;
        top: 0.03rem;
        right: 0.03rem;
        z-index: 1000;
      }
    }
  }
  .bgc-wrap {
    height: 0.4rem;
    width: 100%;
  }
}
.adjust-margin {
  margin-top: -0.1rem;
}
</style>
