import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import vehicleList from "../views/vehicle-list/index.vue";
import vehicleInformation from "../views/vehicle-information/index.vue";
import carPlateDetail from "../views/car-plate-detail/index.vue";
import carVinDetail from "../views/car-vim-detail/index.vue";
import adminVehicleList from "../views/admin-vehicle-list/index.vue";
import search from "../views/search/index.vue";
import scanPopup from "../views/scanPopup/index.vue";
import shearPicture from "../views/shear-picture/index.vue";
import stockTakingOne from "../views/stockTaking/stockTakingOne.vue";
import stockTakingTwo from "../views/stockTaking/stockTakingTwo.vue";
import stockTakingSummary from "../views/stockTaking/stockTakingSummary.vue";
import Error from "../views/error/error.vue";
import chooseRole from "../views/choose-role/index.vue";
import init from "../views/skip/init.vue";
import middle from "../views/skip/middle.vue";
import { langEn } from "../vueI18n/language-en";
import { langZh } from "../vueI18n/language-zh";
import store from "../store/index"
import { getCookie, setCookie } from "../utils/CookieManager";


Vue.use(VueRouter);

var a = ""
if ((navigator.language == 'zh-cn') || (navigator.language == 'zh-CN')) {
  a = 'zh'
} else if ((navigator.language == 'en-us') || (navigator.language == 'en-US')) {
  a = 'en'
}
let lang
if (a == 'zh') {
  lang = langZh
} else {
  lang = langEn
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Vehicle Butler",
      keepAlive: false,
    },
    component: Home,
  },
  {
    path: "/shear-picture",
    name: "shearPicture",
    meta: {
      title: "shearPicture",
      keepAlive: false
    },
    component: shearPicture,
  },

  {
    path: "/vehicle-list",
    name: "vehicleList",
    meta: {
      title: lang.Title,
      keepAlive: true,
    },
    component: vehicleList,
  },
  {
    path: "/admin-vehicle-list",
    name: "adminVehicleList",
    meta: {
      title: lang.Title,
      keepAlive: true,
    },
    component: adminVehicleList,
  },
  {
    path: "/vehicle-information/:id",
    name: "vehicleInformation",
    meta: {
      keepAlive: true,
      title: lang.VehicleInformation,
    },
    component: vehicleInformation,
  },
  {
    path: "/car-plate",
    name: "carPlateDetail",
    meta: {
      title: lang.CarPlateDetail,
      keepAlive: false
    },
    component: carPlateDetail,
  },
  {
    path: "/car-vin",
    name: "carVinDetail",
    meta: {
      title: lang.CarVINDetail,
      keepAlive: false
    },
    component: carVinDetail,
  },
  {
    path: "/search",
    name: "search",
    meta: {
      title: lang.Title,
    },
    component: search,
  },
  {
    path: "/scanPopup",
    name: "scanPopup",
    meta: {
      title: "scanPopup",
      keepAlive: false
    },
    component: scanPopup,
  },
  {
    path: "/stockTakingOne",
    name: "stockTakingOne",
    meta: {
      title: lang.StockTakingOne,
      keepAlive: false
    },
    component: stockTakingOne,
  },
  {
    path: "/stockTakingTwo",
    name: "stockTakingTwo",
    meta: {
      title: lang.StockTakingTwo,
      keepAlive: false
    },
    component: stockTakingTwo,
  },
  {
    path: "/stockTakingSummary",
    name: "stockTakingSummary",
    meta: {
      title: lang.StockTaking,
      keepAlive: false
    },
    component: stockTakingSummary,
  },
  {
    path: "/chooseRole",
    name: "chooseRole",
    meta: {
      title: lang.VehicleButler,
      keepAlive: false
    },
    component: chooseRole,
  },
  {
    path: "/init",
    name: "init",
    meta: {
      title: "init",
      keepAlive: false
    },
    component: init,
  },
  {
    path: "/middle",
    name: "middle",
    meta: {
      title: "middle",
      keepAlive: false
    },
    component: middle,
  },
  {
    path: "/error",
    component: Error,
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

let routerNum = 0
let pushrouterList = []
router.afterEach((to, from, next) => {

  if (to.path !== "/") {
    const codeUrl = window.location.href;
    const code = getCookie("weComCode");
    if (codeUrl.split("=")[1].split("&")[0] != code) {
      window.location.href = "https://vehiclebutler.i.mercedes-benz.com/wecom"
      return
    }
  }


  if (to.name == 'adminVehicleList' || to.name == 'vehicleList') {
    pushrouterList = []
    routerNum = 0
  } else {
    if (pushrouterList.indexOf(to.name) > -1) {
    } else {
      pushrouterList.push(to.name)
      routerNum++
      console.log(pushrouterList)
      // window.localStorage.setItem('routerList', pushrouterList)
      window.localStorage.setItem('routerNum', routerNum)
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.path === '/' && from.path !== '/') {
    wx.closeWindow();
  } else {
    next();
  }
});


export default router;
