import carModels from "../assets/carModels";

function getClassName(regex, model) {
  const result = regex.exec(model);
  return result && result[0].toLowerCase();
}

function findBigClass(model) {
  const aToG = /^[A|B|C|E|R|S|V|G](?=[\s|\d])/gi;
  const specialCar =
    /^(CLA|CLS|GLA|GLC|GLE|GLK|GLS|ML|SL|SLC|smart|Vito)(?=[\s|\d])/gi;
  const specialCarWithoutSpace =
    /^(Sprinter|smart|Denza|maybach|viano|GL400|GL500)/gi;
  const amgGt = /(GT)/gi;
  return (
    getClassName(aToG, model) ||
    getClassName(specialCar, model) ||
    getClassName(specialCarWithoutSpace, model) ||
    getClassName(amgGt, model)
  );
}

function hasChildClass(bigClass) {
  const carModelArrayWithChild = ["c", "cls", "e", "glc", "gle", "s", "smart"];
  const inArray = carModelArrayWithChild.includes(bigClass);
  return bigClass && inArray;
}

function findChildClass(model) {
  const a = /(estate|sedan|sport|couple|maybach|forfour|fortwo)/gi;
  const childClassName = getClassName(a, model);
  return (
    childClassName &&
    childClassName.substring(0, 1).toUpperCase() + childClassName.substring(1)
  );
}

function getImageByModelName(model) {
  const bigClass = findBigClass(model);
  let result = bigClass || "defaultCarModel";
  if (hasChildClass(bigClass)) {
    const r = bigClass + findChildClass(model);
    result = carModels[r] ? r : result;
  }
  return carModels[result];
}

export default getImageByModelName;
