export function parseTelNumber(value) {
  const mobilePattern = /(13|14|15|18|17)[0-9]{9}/;
  const phonePattern = /[0-9]{2}\s[0-9]{8}/;

  if (!value) {
    return "";
  }

  const mobileNumber = mobilePattern.exec(value.replace(/\s/g, ""));
  if (mobileNumber) {
    return mobileNumber[0];
  }
  if (phonePattern.exec(value)) {
    return phonePattern.exec(value)[0];
  }

  return value;
}
