<template>
  <div class="search">
    <div class="search-wrap">
      <div class="icon-search">
        <img src="@/assets/img/icon-search.png" />
      </div>
      <input
        class="input-btn"
        type="text"
        :placeholder="$t('lang.SearchByN')"
        v-model="inputVal"
        maxlength="8"
      />
      <mt-button size="small" @click="goBack">{{
        $t("lang.Cancel")
      }}</mt-button>
    </div>
    <div v-show="haveCar" class="haveCar">
      {{ $t("lang.NoVehiclesFound") }}
    </div>
    <div style="height: 0.1rem; background-color: #f6f6f6"></div>

    <CarListItem :carListData="result"></CarListItem>
  </div>
</template>
<script>
import CarListItem from "@/components/car-list-item.vue";
export default {
  components: {
    CarListItem,
  },
  data() {
    return {
      value: "",
      inputVal: this.$store.state.inputVal,
      result: [],
      haveCar: false,
    };
  },
  created() {
    if (this.$store.state.haveValue == false) {
      this.searchList(this.inputVal);
    }
  },
  methods: {
    async searchList(key) {
      let response = await this.$api.vehicle.searchVehicleList(key);
      if (response) {
        this.result = response;
        this.$store.commit("updateCarInfo", {
          inputVal: this.inputVal,
        });
        if (this.result.length == 0) {
          this.haveCar = true;
        } else {
          this.haveCar = false;
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    inputVal(curVal) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchList(curVal);
      }, 800);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-wrap {
  height: 0.44rem;
  background-color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  .icon-search {
    height: 0.28rem;
    width: 0.3rem;
    background-color: #f6f6f6;
    margin-left: 0.1rem;
    border-top-left-radius: 0.04rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0.04rem;
    img {
      display: block;
      padding-top: 0.04rem;
      padding-left: 0.08rem;
    }
    input {
      height: 100%;
      padding: 0rem;
    }
  }
  .mint-button--small {
    background-color: white;
    box-shadow: none;
    font-size: 0.12rem;
    line-height: 0.14rem;
    height: 0.28rem;
    padding-left: 0.08rem;
    color: #999999;
    flex-grow: 1;
  }
  .mint-button--small::after {
    background-color: white;
  }
}
input {
  height: 0.26rem;
  width: 72%;
  border: 0rem;
  outline: none;
  background-color: #f6f6f6;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0.04rem;
  border-bottom-right-radius: 0.04rem;
  border-bottom-left-radius: 0rem;
  padding-left: 0.04rem;
}
input::-webkit-input-placeholder {
  color: #d8d8d8;
  font-size: 0.12rem;
  line-height: 0.17rem;
}
.haveCar {
  height: calc(100vh - 1.08rem);
  width: 100%;
  margin: auto;
  font-size: 0.13rem;
  color: #333333;
  line-height: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-btn {
  height: 0.28rem;
  padding: 0rem;
}
</style>
