<template>
  <div class="vehicle-list">
    <div class="car-show">
      <div class="title">
        <div class="reference">{{ $t("lang.Reference") }}</div>
        <div class="task" v-if="this.carDetail.stockStatus == 'TASK'">
          {{ $t("lang.Task") }}
        </div>
        <div class="hasTask" v-else-if="this.carDetail.stockStatus == 'DONE'">
          {{ $t("lang.TaskDone") }}
        </div>
        <div
          class="submitTask"
          v-else-if="this.carDetail.stockStatus == 'SUBMIT'"
        >
          {{ $t("lang.SUBMIT") }}
        </div>
      </div>
      <div class="car-img">
        <img :src="fetImage(this.carDetail.model)" alt="" />
      </div>
      <div class="float-wrap">
        <span class="license-information">
          {{ this.carDetail.plateNo || this.carDetail.legacyPlateNo }}
        </span>
        <div class="car-type">{{ this.carDetail.model }}</div>
      </div>
    </div>
    <div class="info-detail">
      <mt-navbar v-model="selected">
        <mt-tab-item id="1">{{ $t("lang.Vehicle") }}</mt-tab-item>
        <mt-tab-item id="2">{{ $t("lang.Related") }}</mt-tab-item>
        <!-- <mt-tab-item id="3">{{ $t("lang.Violation") }}</mt-tab-item> -->
      </mt-navbar>

      <div class="info-content">
        <mt-tab-container v-model="selected">
          <mt-tab-container-item id="1">
            <div class="info-list">
              {{ $t("lang.PlateNumber") }}
              <div class="userDetail">
                {{ this.carDetail.plateNo || this.carDetail.legacyPlateNo }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.VIN") }}
              <div class="userDetail">
                {{ this.carDetail.vin }}
              </div>
            </div>
            <div class="info-list">
              <div class="setWidht">{{ $t("lang.CarModel") }}</div>
              <div class="userDetail">
                {{ this.carDetail.model }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.EngineNo") }}
              <div class="userDetail">
                {{ this.carDetail.engineNo }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.RegisteredCity") }}
              <div class="userDetail">
                {{ this.carDetail.registerArea }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.RegisteredCompany") }}
              <div class="userDetail">
                {{ this.carDetail.registerUnder }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.RegisterationDate") }}
              <div
                class="userDetail"
                v-show="this.carDetail.registerDate != ''"
              >
                {{
                  moment(new Date(this.carDetail.registerDate)).format(
                    "YYYY-MM-DD"
                  ) || ""
                }}
              </div>
            </div>
          </mt-tab-container-item>
          <mt-tab-container-item id="2">
            <div class="info-list">
              {{ $t("lang.User") }}
              <div class="userDetail">
                {{ this.empName }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.Email") }}
              <div class="userDetail">
                {{ this.email }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.Telephone") }}

              <a
                class="userDetailPhone"
                :href="'tel:' + getCallNumber(parseTelNumber(this.businessPhone))"
                >{{ parseTelNumber(this.businessPhone) }}</a
              >
            </div>
            <div class="info-list">
              {{ $t("lang.Mobile") }}

              <a
                class="userDetailPhone"
                :href="'tel:' + parseTelNumber(this.mobilePhone)"
                >{{ parseTelNumber(this.mobilePhone) }}</a>
            </div>
            <div class="info-list">
              {{ $t("lang.Company") }}
              <div
                class="userDetail"
                v-if="this.carDetail.usage === 'Business Car'"
              >
                {{ this.carDetail.businessCarUserCompany }}
              </div>
              <div class="userDetail" v-else>
                {{ this.carDetail.userCompany }}
              </div>
            </div>
            <div class="info-list">
              {{ $t("lang.InsuranceCompany") }}
              <div class="userDetail">
                {{ this.carDetail.insurance.insuranceCompany }}
              </div>
            </div>

            <div class="detail-phone">
              {{ $t("lang.ClientService") }}
              <div class="phoneView">
                <div  class="phone" v-for="(item,index) in this.renderAccidentTel(this.carDetail.insurance.insuranceAccidentTel)">
                    <a class="userDetailPhone" :href="'tel:' + item.nev"
                    >
                      {{item.nev}}
                    </a>
                    {{item.value}}
                  </div>
              </div>
            </div>
          </mt-tab-container-item>
          <!-- <mt-tab-container-item id="3">
            <div
              v-for="(item, index) in this.carDetail.violations"
              :key="index"
            >
              <div class="violation-list" @click="violationBtn(item, index)">
                <div>
                  <mt-button type="default" size="small"
                    >{{ "Point:" }} {{ item.point }}</mt-button
                  >
                  <mt-button type="default" size="small"
                    >{{ "Fines:" }} {{ item.fine }}</mt-button
                  >
                </div>
                <div class="right-wrap">
                  <p class="violation-time">
                    {{ moment(new Date(item.time)).format("YYYY/MM/DD HH:MM") }}
                  </p>
                  <img
                    v-show="!item.isShow"
                    src="@/assets/img/icon-arrowdown.png"
                    alt=""
                  />
                  <img
                    v-show="item.isShow"
                    src="@/assets/img/icon-arrowup.png"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <ul
                  v-show="item.isShow"
                  :class="{ active: toggleType == index }"
                >
                  <li class="violation-content">
                    <p class="violation-content-title">{{ $t("lang.Type") }}</p>
                    <p class="violation-content-content">
                      {{ item.type }}
                    </p>
                  </li>
                  <li class="violation-content">
                    <p class="violation-content-title">
                      {{ $t("lang.Place") }}
                    </p>
                    <p class="violation-content-content">
                      {{ item.location }}
                    </p>
                  </li>
                  <li class="violation-content">
                    <p class="violation-content-title">
                      {{ $t("lang.Detail") }}
                    </p>
                    <p class="violation-content-content">
                      {{ item.detail }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="haveCar" v-show="this.carDetail.violations.length == 0">
              {{ $t("lang.NoViolationRecord") }}
            </div>
          </mt-tab-container-item> -->
        </mt-tab-container>
      </div>
    </div>
    <div class="occupied-botton"></div>
    <div class="bottom-btn">
      <mt-button
        type="primary"
        size="large"
        :disabled="this.disabled"
        :class="showType"
        @click="goToStockTaking"
        v-if="this.carDetail.stockStatus == 'TASK'"
        >{{ $t("lang.TakeStock") }}</mt-button
      >
      <mt-button
        type="primary"
        size="large"
        :disabled="this.disabled"
        :class="showType"
        @click="goToStockTaking"
        v-else-if="this.carDetail.stockStatus == 'DONE'"
        >{{ $t("lang.TaskDone") }}</mt-button
      >
      <mt-button
        type="primary"
        size="large"
        :disabled="this.disabled"
        :class="showType"
        @click="goToStockTaking"
        v-else-if="this.carDetail.stockStatus == 'SUBMIT'"
        >{{ $t("lang.SUBMIT") }}</mt-button
      >
    </div>
  </div>
</template>
<script>
import ImageMapper from "@/utils/CarImageMapper";
import { parseTelNumber } from "@/utils/mobileNumber";
export default {
  name: "Vehicle-Information",
  data() {
    return {
      selected: "1",
      violation: false,
      toggleType: -1,
      carDetail: {
        insurance: { insuranceCompany: "", insuranceAccidentTel: "" },
        violations: [],
      },
      empName: "",
      email: "",
      mobilePhone: "",
      businessPhone: "",
      clientService: "",
      disabled: true,
      result: [],
    };
  },
  deactivated() {
    (this.selected = "1"),
      (this.violation = false),
      (this.toggleType = -1),
      (this.carDetail = {
        insurance: { insuranceCompany: "", insuranceAccidentTel: "" },
        violations: [],
      }),
      (this.empName = ""),
      (this.email = ""),
      (this.mobilePhone = ""),
      (this.businessPhone = ""),
      (this.clientService = ""),
      (this.disabled = true),
      (this.result = []);
  },
  activated() {
    this.fetchVehicleDetail();
    this.$store.commit("updateCarInfo", {
      haveValue: false,
      information:true
    });
  },
  computed: {
    showType() {
      if (this.carDetail.stockStatus == "TASK") {
        this.disabled = false;
        return "verifyBtn";
      }
    },
  },
  watch: {
    selected() {
      document.getElementsByClassName("info-content")[0].scrollTop = 0;
      this.toggleType = -1;
      this.carDetail.violations.forEach((e) => {
        e.isShow = false;
      });
    },
  },

  created() {
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
              Object.assign(
                      {},
                      this.$store.state,
                      JSON.parse(sessionStorage.getItem('store'))
              )
      )
    }
  },

  methods: {
    fetImage(model) {
      return ImageMapper(model);
    },
    violationBtn(item, index) {
      item.isShow = !item.isShow;
      this.violation = item.isShow;
      if (this.toggleType != index) {
        this.toggleType = index;
      } else {
        this.toggleType = -1;
      }
    },
    async fetchVehicleDetail() {
      let response = await this.$api.vehicle.fetchVehicleDetail(
        this.$route.params.id
      );
      if (response) {
        this.carDetail = response;
        if (response.userDetail == null) {
          (this.empName = ""),
            (this.email = ""),
            (this.mobilePhone = ""),
            (this.businessPhone = ""),
            (this.clientService = "");
        } else {
          if (this.carDetail.userDetail.mobilePhone != "") {
            this.mobilePhone = parseTelNumber(
              this.carDetail.userDetail.mobilePhone
            );
          } else {
            this.mobilePhone = "";
          }
          (this.empName = response.userDetail.empName),
            (this.email = response.userDetail.email),
            (this.businessPhone = parseTelNumber(
              this.carDetail.userDetail.businessPhone
            ));
          this.clientService = parseTelNumber(
            this.carDetail.insurance.insuranceAccidentTel
          );
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.path == "/admin-vehicle-list") {
        to.meta.keepAlive = true;
      } else if (to.path == "/vehicle-list") {
        to.meta.keepAlive = true;
      } else if (to.path == "/search") {
        to.meta.keepAlive = true;
      } else {
        to.meta.keepAlive = false;
      }
      next();
    },

    getCallNumber(tel) {
      return tel.replace(/\s*/g,"");
    },

    parseTelNumber(value) {
      const mobilePattern = /(13|14|15|18|17)[0-9]{9}/
      const phonePattern = /[0-9]{2}\s[0-9]{8}/

      if(!value) {
        return ''
      }

      const mobileNumber = mobilePattern.exec(value.replace(/\s/g, ''))
      if (mobileNumber) {
        return mobileNumber[0]
      }
      if (phonePattern.exec(value)) {
        return phonePattern.exec(value)[0]
      }

      return value
    },

    renderAccidentTel(tel) {
      const telArray = this._.words(tel,/\d{5,}/g)
      let telString = this._.split(tel,/\d{5,}/g)
      telString = telString.filter((v) => !this._.isEmpty(v))
      const telElements = telArray.map((v, index) => (
              {"nev": v, "value":telString[index]}
      ));
      if (this._.isEmpty(telElements)) {
        return [{"nev": tel, "value": ""}]
      } else {
        return  telElements
      }
    },

    goToStockTaking() {
      this.$store.commit("updateCarInfo", {
        carModel: this.carDetail.model,
        plateNumber: this.carDetail.plateNo || this.carDetail.legacyPlateNo,
        id: this.carDetail.id,
        VIN: this.carDetail.vin,
      });
       sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      this.$router.push({
        path: "/car-plate",
        query: {
          code: "code",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.car-show {
  position: relative;
  height: 2.8rem;
  background-color: white;
  margin-bottom: 0.1rem;
  .title {
    position: absolute;
    position: relative;
    top: 0.24rem;
    display: flex;
    justify-content: space-between;
    .reference {
      height: 0.17rem;
      padding-left: 0.24rem;
      font-size: 0.12rem;
      color: #999999;
      line-height: 0.17rem;
      align-items: center;
    }
    .task {
      position: absolute;
      right: 0rem;
      width: 0.61rem;
      height: 0.16rem;
      background: #4c9edd;
      font-size: 0.1rem;
      // line-height: 0.16rem;
      display: flex;
      align-items: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }
    .hasTask {
      right: 0.24rem;
      width: 0.61rem;
      height: 0.16rem;
      background-color: #07c160;
      font-size: 0.1rem;
      // line-height: 0.16rem;
      display: flex;
      align-items: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }
    .submitTask {
      right: 0.24rem;
      width: 0.61rem;
      height: 0.16rem;
      background-color: #d8d8d8;
      font-size: 0.1rem;
      // line-height: 0.16rem;
      display: flex;
      align-items: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
    }
  }
  .car-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 2.7rem;
    }
  }
  .float-wrap {
    position: absolute;
    bottom: 0.17rem;
    text-align: center;
    width: 100%;
  }
  .license-information {
    border: 1px solid #d8d8d8;
    padding: 0rem 0.04rem;
    height: 0.24rem;
    font-size: 0.16rem;
    line-height: 0.23rem;
    font-weight: 600;
    background-color: #ebebeb;
    text-align: center;
  }
  .car-type {
    height: 0.2rem;
    color: #333333;
    line-height: 0.2rem;
    font-size: 0.14rem;
    display: flex;
    justify-content: center;
    margin-top: 0.09rem;
  }
}
.info-detail {
  height: calc(100vh - 3.46rem);
  width: 100%;
  background-color: white;
  .info-content {
    height: calc(100% - 54px);
    overflow: auto;
  }
  .nav {
    width: 100%;
    height: 0.48rem;
    display: flex;
    justify-content: space-around;
    .titleInfo {
      font-weight: 600;
      color: #999999;
      line-height: 0.2rem;
      font-size: 0.14rem;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
  .info-list {
    padding: 0.13rem 0rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: #333333;
    background-color: #ffffff;
    border-bottom: solid 0.01rem #f6f6f6;
    align-items: center;
    display: flex;
    padding-left: 0.2rem;
    justify-content: space-between;
    .setWidht {
      min-width: 30%;
    }
    .userDetail {
      padding-right: 0.2rem;
      font-size: 0.14rem;
      color: #666666;
      line-height: 0.2rem;
      text-align: right;
    }
    .userDetailPhone {
      padding-right: 0.2rem;
      font-size: 0.14rem;
      text-decoration: none;
      line-height: 0.2rem;
    }
  }
  .detail-phone {
    height: 0.46rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: #333333;
    background-color: #ffffff;
    border-bottom: solid 0.01rem #f6f6f6;
    align-items: center;
    display: flex;
    padding-left: 0.2rem;
    justify-content: space-between;
    .phoneView {
      padding-right: 0.2rem;
    }
    .phone {
      display:inline-block;
    }
    .userDetail {
      padding-right: 0.2rem;
      font-size: 0.14rem;
      color: #666666;
      line-height: 0.2rem;
    }
    .userDetailPhone {
      font-size: 0.14rem;
      text-decoration: none;
      line-height: 0.2rem;
    }
    span {
      padding-right: 0.2rem;
    }
  }
  .violation-list {
    height: 0.46rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    padding: 0rem 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 0.01rem #f6f6f6;
    .mint-button {
      height: 0.22rem;
      line-height: 0.17rem;
      font-size: 0.12rem;
      margin-right: 0.08rem;
    }
    .violation-time {
      font-size: 0.14rem;
      color: #666666;
    }
    img {
      padding-left: 0.1rem;
    }
  }
  .violation-content {
    display: flex;
    font-size: 0.14rem;
    line-height: 0.2rem;
    padding: 0.1rem 0rem;
    background: #f6f6f6;
    border-bottom: solid 0.01rem #ebebeb;
    .violation-content-title {
      color: #333333;
      padding-left: 0.2rem;
      padding-right: 0.55rem;
    }
    .violation-content-content {
      width: 2.52rem;
      color: #666666;
    }
  }
}
.bottom-btn {
  font-family: PingFangSC;
  height: 0.56rem;
  width: 100%;
  position: fixed;
  bottom: 0rem;
  .mint-button--primary {
    height: 100%;
    border-radius: 0%;
    line-height: 0.25rem;
    font-size: 0.18rem;
    background-color: #d8d8d8;
  }
}
.btn-background {
  height: 0.56rem;
  width: 100%;
}
.right-wrap {
  display: flex;
}
.haveCar {
  height: calc(100vh - 4.7rem);
  width: 100%;
  margin: auto;
  font-size: 0.13rem;
  color: #333333;
  line-height: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  display: block;
}
.occupied-botton {
  height: 0.56rem;
  background-color: #ffffff;
}
</style>
