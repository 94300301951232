<template>
  <div class="car-plate-detail">
    <p class="input-car-plate-title">
      {{ $t("lang.InputCarVINInformation") }}
    </p>
    <div class="nput-car-plate">
      <div class="license-plate-number">{{ "VIN" }}</div>
      <input
        type="text"
        name=""
        id="input-plate-num"
        onkeyup="toUpperCase(this)"
        v-model="vinNumber"
        maxlength="17"
      />
    </div>

    <div class="stock-taking-next-btn">
      <mt-button
        size="large"
        class="stock-taking-btn"
        :disabled="this.disabled"
        :class="toggleType"
        @click="goToNext"
        >{{ $t("lang.Next") }}</mt-button
      >
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      vinNumber: this.$store.state.VIN,
      disabled: true,
      checkResult: [],
    };
  },
  computed: {
    toggleType() {
      if (this.vinNumber.length == 17) {
        this.disabled = false;
        return "verifyBtn";
      }
    },
  },
  watch: {
    vinNumber() {
      this.vinNumber = this.vinNumber.toUpperCase();
      if (this.vinNumber.length > 17) {
        this.vinNumber = this.vinNumber.substring(0, 17);
      }
    },
  },
  created() {
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
              Object.assign(
                      {},
                      this.$store.state,
                      JSON.parse(sessionStorage.getItem('store'))
              )
      )
    }
    if (this.$store.state.scanSuccess) {
      Toast({
        message: this.$t("lang.ScanSuccess"),
        duration: 3000,
        className: "ToastTop",
      });
      this.$store.state.scanSuccess = false;
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  methods: {
    show() {
      Toast({
        message: this.$t("lang.CheckPlateVIN"),
        position: "top",
        duration: 1000,
      });
    },
    async searchList(key) {
      let response = await this.$api.vehicle.searchVehicleList(key);
      if (response) {
        this.checkResult = response;
        if (this.checkResult[0]) {
          if (!this.checkResult[0].plateNo) {
            this.checkResult[0].legacyPlateNo =
              "京" + this.checkResult[0].legacyPlateNo;
          } else {
            this.checkResult[0].legacyPlateNo = this.checkResult[0].plateNo;
          }
          if (this.checkResult[0].stockStatus !== 'TASK') {
            this.$router.push(`/vehicle-information/${this.checkResult[0].id}`);
          } else {
            this.$store.commit("updateCarInfo", {
              carModel: this.checkResult[0].model,
              plateNumber: this.checkResult[0].legacyPlateNo,
              VIN: this.checkResult[0].vin,
            });
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
            this.$router.push("/stockTakingOne");
          }
        } else {
          this.show();
        }
      }
    },
    goToNext() {
      this.searchList(this.vinNumber);
    },
    toUpperCase(obj) {
      obj.value = obj.value.toUpperCase();
    },
  },
};
</script>
<style lang="scss" scoped>
.car-plate-detail {
  background: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  .input-car-plate-title {
    height: 0.18rem;
    font-size: 0.13rem;
    line-height: 0.18rem;
    color: #333333;
    padding-top: 0.48rem;
    padding-bottom: 0.24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .nput-car-plate {
    width: 100%;
    height: 0.52rem;
    border-bottom: solid 0.01rem #ebebeb;
    border-top: solid 0.01rem #ebebeb;
    display: flex;
    align-items: center;
    .license-plate-number {
      width: 1.12rem;
      height: 0.2rem;
      font-size: 0.14rem;
      color: #333333;
      line-height: 0.2rem;
      border-right: solid 0.01rem #ebebeb;
      display: flex;
      justify-content: center;
      margin-right: 0.2rem;
    }
    #input-plate-num {
      border-width: 0;
      outline: none;
    }
  }
  .bottom-btn {
    padding-top: 0.32rem;
    display: flex;
    justify-content: center;
    .mint-button--normal {
      width: 3.15rem;
      height: 0.4rem;
      border-radius: 0%;
      font-size: 0.16rem;
      line-height: 0.22rem;
    }
  }
}
.stock-taking-next-btn {
  padding-top: 0.32rem;
}
</style>
