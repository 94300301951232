<template>
  <div class="popUpPlateRegion">
    <mt-popup v-model="colorPopUp" position="bottom" closeOnClickModal="false">
      <div>
        <div
          class="list"
          v-for="(item, index) in dataList"
          :key="index"
          @click="chooseItem(item)"
        >
          {{ item }}
        </div>
        <div class="cancelBtn">
          <mt-button type="primary" size="small" @click="closeOnClickModal">{{
            $t("lang.Cancel")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>
<script>
export default {
  name: "car-list-item",
  data() {
    return {
      state: false,
    };
  },
  props: {
    colorPopUp: {
      type: Boolean,
    },
    dataList: {
      type: Array,
    },
  },
  methods: {
    closeOnClickModal() {
      this.$emit("cancle", this.state);
    },
    chooseItem(item) {
      this.$emit("chooseItem", item);
      this.closeOnClickModal();
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.popUpPlateRegion {
  background-color: #f6f6f6;
  .mint-popup-bottom {
    width: 100%;
    overflow: scroll;
    font-size: 0.16rem;
    line-height: 0.22rem;
    display: flex;
    justify-content: center;
    background-color: #ffffff;

    .list {
      width: 100%;
      display: flex;
      align-items: center;
      list-style-type: none;
      justify-content: center;
      height: 0.39rem;
      border-bottom: solid 0.01rem #f6f6f6;
      color: #4c9edd;
      font-family: PingFangSC;
    }
    .cancelBtn {
      height: 0.39rem;
      width: 3.75rem;
      border-top: solid #f6f6f6 0.1rem;
      background-color: white;
      display: flex;
      justify-content: center;
      .mint-button--primary {
        background-color: white;
        color: #666666;
        font-size: 0.16rem;
        line-height: 0.22rem;
      }
    }
  }
}
</style>
