import { post, fetchImage, get } from "../axiosconfig/axiostool";

export default {
  userLogin(param) {
    return post("/vehicle-butler/api/login", param);
  },

  userLoginByCode(code) {
    return get("/vehicle-butler/api/getUserInfoByCode?code=" + code);
  },

  fetchVerifyCode() {
    return fetchImage("/vehicle-butler/api/captcha?" + Math.random());
  },
};
