<template>
  <div id="app">
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1.0,
minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
    />
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        v-wechat-title="$route.meta.title"
      />
    </keep-alive>

    <router-view
      v-if="!$route.meta.keepAlive"
      v-wechat-title="$route.meta.title"
    />
  </div>
</template>
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
export default {
  data() {
    return {};
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss">
#app {
}
</style>
