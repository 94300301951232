export const langZh = {
  Title: "车辆列表",
  VehicleInformation: "车辆详情",
  CarPlateDetail: "车牌信息",
  CarVINDetail: "VIN信息",
  StockTakingOne: "车辆盘点 (1/2)",
  StockTakingTwo: "车辆盘点 (2/2)",
  StockTaking: "车辆盘点",
  Task: "待盘点",
  SUBMIT: "已提交",
  TaskDone: "已盘点",
  Counted: "已盘点",
  Reference: "*参考图片",
  Vehicle: "车辆信息",
  Related: "相关信息",
  Violation: "违章信息",
  PlateNumber: "车牌号码",
  VIN: "车辆识别码（VIN）",
  CarModel: "车型",
  EngineNo: "引擎号",
  RegisteredCity: "注册城市",
  RegisteredCompany: "注册公司",
  RegisterationDate: "注册日期",
  User: "使用人",
  Email: "邮箱",
  Telephone: "电话",
  Mobile: "手机",
  Company: "公司",
  InsuranceCompany: "保险公司",
  ClientService: "客服电话",
  Type: "类型",
  Place: "地点",
  Detail: "详情",
  TakeStock: "盘点",
  InputCarPlateInformation: "输入车牌信息",
  InputCarVINInformation: "输入VIN码信息",
  CarPlateNo: "车牌号",
  Next: "下一步",
  NoVehiclesFound: "暂无车辆",
  ChooseScanType: "选择扫描对象",
  ScanCarPlate: "扫描车牌",
  ManualInput: "手动输入",
  CameraScan: "相机扫描",
  ScanVINCode: "扫描VIN码",
  noPlateNumber: "无车牌",
  SearchByN: "输入车牌号/VIN码进行搜索",
  Close: "关闭",
  NoViolationRecord: "没有违章记录",
  ScanFailed: "扫描失败",
  ScanSuccess:"扫描成功",
  Retry: "重试",
  AssignedToMe: "我的任务车辆",
  All: "全部",
  Cancel: "取消",
  VehicleBasicInformation: "车辆基本信息",
  Location: "定位",
  ExteriorColour: "车身颜色",
  MyLocation: "获取我的位置",
  InteriorPhoto: "内饰颜色",
  Required: "必填项",
  ExteriorPhoto: "车身照片",
  PleaseTakePhotos: "请拍摄完整车身，并确保车牌清晰",
  LeftFront: "左前方",
  RightBack: "右后方",
  TapHereToAddPhoto: "点击拍摄机动车行驶证",
  White: "白色",
  Silver: "银色",
  Grey: "灰色",
  Blue: "蓝色",
  Green: "绿色",
  Red: "红色",
  Yellow: "黄色",
  Orange: "橙色",
  Black: "黑色",
  Brown: "棕色",
  Beige: "米色",
  Other: "其它",
  VehicleSpecificInformation: "车辆盘点信息",
  WarningTriangleInCar: "是否配备三角警示牌",
  FireExtinguisher: "是否配备灭火器",
  FloorMat: "是否配备脚垫",
  Mileage: "车辆里程信息",
  MileageNumber: "里程数",
  MileageNumberRequired: "请输入总里程数",
  OdometerPhoto: "里程表照片",
  Damages: "车辆损伤信息",
  PleaseChooseHowManyPanelsToRepairBelow: "请选择损伤程度及需要修理部位的数量",
  Perfect: "无",
  Minor: "轻微",
  Medium: "中等",
  Major: "严重",
  carIsMobileAndInUse: "车辆是否可以驾驶/使用",
  PleaseAttachOnePhoto: "请添加损伤部位照片1张",
  PleaseAttachTwoPhoto: "请添加损伤部位照片2张",
  PleaseAttachMoreThanThreePhoto: "请添加损伤部位照片不少于3张",
  DamageIndication: "选择损伤部位",
  TapToIndicateWhereYourVehicleIsDamaged: "请点击蓝色区域选择车辆损伤部位",
  MyLocationObtained: "已获取我的位置",
  VehicleLicense: "机动车行驶证",
  DamageLevel: "损伤程度",
  DamagePhoto: "损伤照片",
  NewEnergyVehicle: "新能源车",
  DigitalSignature: "电子签名",
  PleaseSignYourNameBelow: "请签署本人姓名",
  Clear: "重签",
  CheckPlateNumber: "你不能盘点此车辆，请检查车牌号码",
  CheckPlateVIN: "你不能盘点此车辆，请检查VIN码",
  Tosubmit: "提交",
  success: "成功",
  TipOfSuccess:
    "我们需要1-3天的时间对盘点结果进行审核，您可以在车辆列表中看到审核结果。",
  Ikonw: "我知道了",
  PageUnavailable: "本页面无法访问",
  LoginIssues: "如遇登录问题请联系：",
  ContactCompanycarstocktaking: "companycarstocktaking@daimler.com",
  ChooseRole: "请选择您的角色",
  Employee: "公司租车用户",
  FleetAdmin: "Fleet Admin",
  Yes: "是",
  No: "否",
  VehicleButler: "Vehicle Butler"
};
