<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @imgLoad="imgLoad"
          @realTime="realTime"
        >
        </vue-cropper>
      </div>
      <div class="footer-btn">
        <div class="scope-btn">
          <label class="btn" for="uploads">选择图片</label>
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="selectImg($event)"
          />
        </div>
        <div class="upload-btn">
          <button size="mini" type="success" @click="uploadImg()">
            上传图片 <i class="el-icon-upload"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="show-preview">
      <div :style="previews.div" class="preview">
        <img :src="previews.url" :style="previews.img" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  props: ["Name"],
  data() {
    return {
      name: this.Name,
      previews: {},
      option: {
        img: "",
        outputSize: 1,
        outputType: "jpeg",
        info: true,
        canScale: true,
        autoCrop: true,
        autoCropWidth: 630,
        autoCropHeight: 550,
        fixed: false,
        fixedNumber: [1.53, 1],
        full: true,
        fixedBox: false,
        canMove: false,
        canMoveBox: true,
        original: false,
        centerBox: true,
        height: true,
        infoTrue: false,
        maxImgSize: 4096,
        enlarge: 1,
        mode: "375px 487px",
      },
    };
  },
  methods: {
    realTime(data) {
      this.previews = data;
    },
    selectImg(e) {
      let file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error",
        });
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      reader.readAsDataURL(file);
    },
    uploadImg() {
      this.$refs.cropper.getCropData(async (data) => {
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cropper-crop-box {
  width: 100% !important;
  height: 100% !important;
}
.cropper-content {
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 100vh;
      .vue-cropper {
        background-image: none;
      }
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    margin-top: 1rem;
    .preview {
      overflow: hidden;
      border: 0.01rem solid #67c23a;
      background: #cccccc;
    }
  }
}
.footer-btn {
  margin-top: 0.3rem;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 0.1rem;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
}
</style>
