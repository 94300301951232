import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    userType: "",
    userToken: "",
    plateNumber: "",
    carModel: "",
    VIN: "",
    carBodyPhoto: "",
    interiorPhoto: "",
    warningTriangleInCar: "",
    fireExtinguisher: "",
    floorMat: "",
    mileage: "",
    damages: "",
    carIsMobileAndInUse: "",
    result: "",
    leftFrontResult: "",
    rightBackResult: "",
    exteriorPhoto: "",
    photoList: [],
    latitude: "",
    longitude: "",
    id: "",
    choosePerfect: "",
    chooseMinor: "",
    chooseMedium: "",
    chooseMajor: "",
    inputVal: "",
    haveValue: false,
    damageImgList: [],
    employeeOrAdmin: "",
    isRefresh: false,
    routerNum: 0,
    hasStock: false,
    routerList:[],
    information:false,
    scanSuccess:false
  },
  mutations: {
    updateLogin(state, payload) {
      state.isLogin = payload;
    },
    empty() {
      state.routerNum = 0
    },
    addRouterNum(state) {
      state.routerNum++
    },
    pushrouterList(state,payload){
      state.routerList.push(payload)
    },
    updateUserToken(state, payload) {
      state.userToken = payload;
    },
    updateCarInfo(state, payload) {
      Object.assign(state, payload);
    },
    inventoryInformation(state, payload) {
      Object.assign(state, payload);
    },
  },
  actions: {
    isLogin: (state) => state.isLogin,
    userToken: (state) => state.userToken,
  },
  modules: {},
});
