import a from "./car-model/a-class.jpg";
import b from "./car-model/b-class.jpg";
import cCoupe from "./car-model/c-class-coupe.jpg";
import cEstate from "./car-model/c-class-estate.jpg";
import cSedan from "./car-model/c-class-sedan.jpg";
import cSport from "./car-model/c-class-sport.jpg";
import cSportSedan from "./car-model/c-class-sport-sedan.jpg";
import cla from "./car-model/cla.jpg";
import clsSportSedan from "./car-model/cls-sport-sedan.jpg";
import clsShootingBrake from "./car-model/cls-shooting-brake.jpg";
import e from "./car-model/e-class.jpg";
import eCabriolet from "./car-model/e-class-cabriolet.jpg";
import eCoupe from "./car-model/e-class-coupe.jpg";
import eSedan from "./car-model/e-class-sedan.jpg";
import eSportSedan from "./car-model/e-class-sport-sedan.jpg";
import g from "./car-model/g-class.jpg";
import r from "./car-model/r-class.jpg";
import s from "./car-model/s-class.jpg";
import sCoupe from "./car-model/s-class-coupe.jpg";
import sMaybach from "./car-model/s-class-maybach.jpg";
import v from "./car-model/v-class.jpg";
import ml from "./car-model/ml.jpg";
import sl from "./car-model/sl.jpg";
import slc from "./car-model/slc.jpg";
import gla from "./car-model/gla.jpg";
import gle from "./car-model/gle.jpg";
import gleCoupe from "./car-model/gle-coupe.jpg";
import glcCoupe from "./car-model/glc-coupe.jpg";
import glcSuv from "./car-model/glc-suv.jpg";
import glk from "./car-model/glk.jpg";
import gls from "./car-model/gls.jpg";
import denza from "./car-model/denza.jpg";
import smartFortwo from "./car-model/smart-fortwo.jpg";
import smartForfour from "./car-model/smart-forfour.jpg";
import sprinter from "./car-model/sprinter.jpg";
import vito from "./car-model/vito.jpg";
import viano from "./car-model/viano.jpg";
import gt from "./car-model/amg-gt.jpg";
import defaultCarModel from "./car-model/default.jpg";

const c = cSedan;
const cls = clsSportSedan;
const glc = glcSuv;
const gl400 = gle;
const gl500 = gls;
const maybach = sMaybach;
const smart = smartFortwo;

const carModels = {
  a,
  b,
  c,
  cCoupe,
  cEstate,
  cSedan,
  cSport,
  cSportSedan,
  e,
  eCabriolet,
  eCoupe,
  eSedan,
  eSportSedan,
  g,
  r,
  s,
  sCoupe,
  sMaybach,
  v,
  ml,
  sl,
  slc,
  gla,
  glc,
  glcCoupe,
  glcSuv,
  gle,
  gleCoupe,
  glk,
  gls,
  cla,
  cls,
  clsSportSedan,
  clsShootingBrake,
  denza,
  smart,
  smartFortwo,
  smartForfour,
  maybach,
  sprinter,
  vito,
  viano,
  gt,
  gl400,
  gl500,
  defaultCarModel,
};

export default carModels;
