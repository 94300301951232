<template>
  <div class="admin-vehicle-list" v-wechat-title="$route.meta.title">
    {{ result }}
    <div class="fixed-wrap">
      <div class="search-wrap">
        <img src="@/assets/img/icon-scan.png" alt="" @click="popupVisibleBtn" />
        <input
          type="text"
          :placeholder="$t('lang.SearchByN')"
          @click="goToSearch"
        />
      </div>

      <div class="choose-type">
        <mt-checklist v-model="value" :options="options" @change="option()">
        </mt-checklist>
      </div>
    </div>
    <div class="bgc-wrap"></div>

    <mt-loadmore :top-method="loadTop" ref="loadmore">
      <ul
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="moreLoading"
        infinite-scroll-distance="50"
      >
        <div class="line-wrap"></div>
        <div v-show="carListData == '' && this.noVehicleStatus" class="haveCar">
          {{ $t("lang.NoVehiclesFound") }}
        </div>
        <!-- <div class="carListData-wrap" v-show="this.totalRecordCount > 0"> -->
        <CarListItem
          :carListData="carListData"
          v-show="this.carListData != ''"
        ></CarListItem>
        <!-- </div> -->
        <li class="more_loading" v-show="!queryLoading">
          <mt-spinner
            type="snake"
            color="#00ccff"
            :size="15"
            v-show="moreLoading && !allLoaded"
          ></mt-spinner>
        </li>
      </ul>
    </mt-loadmore>
    <mt-popup v-model="popupVisible" @touchmove.native.stop.prevent>
      <div class="pop-up-title">
        <p class="title-bold">{{ $t("lang.ChooseScanType") }}</p>
        <div class="scan-car-plate">
          <p>{{ $t("lang.ScanCarPlate") }}</p>
          <div class="plate-num">
            <span class="site">京</span>
            <span class="mantissa">XXXXXX</span>
          </div>
          <mt-button type="default" @click="manualWrittenPlate">{{
            $t("lang.ManualInput")
          }}</mt-button>
          <mt-button type="primary" @click="goCameraScan">{{
            $t("lang.CameraScan")
          }}</mt-button>
        </div>
        <div class="space-between"></div>
        <div class="scan-car-plate">
          <p>{{ $t("lang.ScanVINCode") }}</p>
          <div class="plate-num">
            <div class="vin-num">LSV12345678900000</div>
          </div>
          <mt-button type="default" @click="manualWrittenVIN">{{
            $t("lang.ManualInput")
          }}</mt-button>
          <mt-button type="primary" @click="goVINScan">{{
            $t("lang.CameraScan")
          }}</mt-button>
        </div>
        <div class="closeBtn">
          <mt-button
            type="default"
            class="closeBtn"
            @click="closeOnClickModal"
            >{{ $t("lang.Close") }}</mt-button
          >
        </div>
      </div>
    </mt-popup>
    <mt-popup v-model="popupVisibleFail" popup-transition="popup-fade">
      <div class="pop-up-wrap">
        <img src="@/assets/img/wrong-sign.png" alt="" />
        <p>{{ $t("lang.ScanFailed") }}</p>
        <div class="bottom-btn">
          <mt-button
            type="primary"
            size="normal"
            class="try-btn"
            @click="tryBtn"
            >{{ $t("lang.Retry") }}</mt-button
          >
          <mt-button
            type="primary"
            size="normal"
            class="next-btn"
            @click="writingBtn"
            >{{ $t("lang.ManualInput") }}</mt-button
          >
        </div>
      </div>
    </mt-popup>
  </div>
</template>
<script>
import { dataURLtoFile } from "@/utils/DataURLtoFile";
import CarListItem from "@/components/car-list-item.vue";
import remoteLoad from "../../assets/remoteload";
import { Indicator } from "mint-ui";

export default {
  name: "Admin-Vehicle-List",
  components: {
    CarListItem,
  },
  data() {
    return {
      carListData: [],
      queryLoading: false,
      moreLoading: false,
      allLoaded: false,
      totalNum: 0,
      pageSize: 15,
      pageNum: 1,
      value: ["B"],
      popupVisible: false,
      check: false,
      popupVisibleFail: false,
      task: true,
      result: "",
      VINPhoto: "",
      sacnPhotoResult: "",
      changeBtn: false,
      photoOrVin: true,
      totalRecordCount: 0,
      noVehicleStatus: false,
      hasCreated: false,
      options: [
        {
          label: this.$t("lang.Counted"),
          value: "A",
          disabled: false,
        },
        {
          label: this.$t("lang.AssignedToMe"),
          value: "B",
          disabled: false,
        },
        {
          label: this.$t("lang.All"),
          value: "C",
          disabled: false,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    loadMore() {
      if (this.value.length != 0 && this.carListData.length != 0) {
        if (this.allLoaded) {
          this.moreLoading = true;
          return;
        }
        if (this.queryLoading) {
          return;
        }
        this.moreLoading = !this.queryLoading;
        if (window.location.href.includes("/admin-vehicle-list")) {
          this.pageNum++;
          this.fetchVehicleList(this.check, this.task);
        }
      }
    },
    async sacnPhoto(key) {
      this.photoOrVin = true;
      let response = await this.$api.vehicle.sacnPhoto(key);
      if (response) {
        if (response.status_code == 4036) {
          this.popupVisibleFail = true;
          this.popupVisible = false;
          Indicator.close();
        } else {
          Indicator.close();
          this.sacnPhotoResult = response;
          this.$store.commit("updateCarInfo", {
            plateNumber: this.sacnPhotoResult,
            scanSuccess: true,
          });
           sessionStorage.setItem("store", JSON.stringify(this.$store.state));
          this.$router.push("/car-plate");
        }
      }
    },
    async sacnVIN(key) {
      this.photoOrVin = false;
      let response = await this.$api.vehicle.sacnVIN(key);
      if (response) {
        if (response.status_code == 4036) {
          this.popupVisibleFail = true;
          this.popupVisible = false;
          Indicator.close();
        } else {
          Indicator.close();
          this.sacnPhotoResult = response;
          this.$store.commit("updateCarInfo", {
            VIN: this.sacnPhotoResult,
            scanSuccess: true,
          });
           sessionStorage.setItem("store", JSON.stringify(this.$store.state));
          this.$router.push("/car-vin");
        }
      }
    },
    goCameraScan() {
      this.popupVisible = false;
      var that = this;
      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["camera"],
        success(res) {
          wx.getLocalImgData({
            localId: res.localIds[0],
            success(ew) {
              if (ew.localData.indexOf("data:image") == 0) {
                that.VINPhoto = ew.localData;
                that.sacnPhoto(dataURLtoFile(that.VINPhoto));
                Indicator.open({
                  text: "Scanning...",
                  spinnerType: "fading-circle",
                });
              } else {
                that.VINPhoto =
                  "data:image/jpeg;base64," + ew.localData.replace(/\n/g, "");
                that.sacnPhoto(dataURLtoFile(that.VINPhoto));
                Indicator.open({
                  text: "Scanning...",
                  spinnerType: "fading-circle",
                });
              }
            },
          });
        },
      });
    },
    goVINScan() {
      var that = this;
      this.popupVisible = false;
      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["camera"],
        success(res) {
          wx.getLocalImgData({
            localId: res.localIds[0],
            success(ew) {
              if (ew.localData.indexOf("data:image") == 0) {
                Indicator.open({
                  text: "Scanning...",
                  spinnerType: "fading-circle",
                });
                that.VINPhoto = ew.localData;

                that.sacnVIN(dataURLtoFile(that.VINPhoto));
              } else {
                Indicator.open({
                  text: "Scanning...",
                  spinnerType: "fading-circle",
                });
                that.VINPhoto =
                  "data:image/jpeg;base64," + ew.localData.replace(/\n/g, "");
                that.sacnVIN(dataURLtoFile(that.VINPhoto));
              }
            },
          });
        },
      });
    },
    tryBtn() {
      if (this.photoOrVin) {
        this.popupVisibleFail = false;
        this.goCameraScan();
      } else {
        this.popupVisibleFail = false;
        this.goVINScan();
      }
      this.popupVisibleFail = false;
    },
    writingBtn() {
      if (this.photoOrVin) {
        this.$store.commit("updateCarInfo", {
          plateNumber: "",
        });
         sessionStorage.setItem("store", JSON.stringify(this.$store.state));
        this.$router.push("/car-plate");
      } else {
        this.$store.commit("updateCarInfo", {
          VIN: "",
        });
         sessionStorage.setItem("store", JSON.stringify(this.$store.state));
        this.$router.push("/car-vin");
      }
    },
    closeOnClickModal() {
      this.popupVisible = false;
    },
    loadTop() {
      if (this.value.length != 0) {
        this.$refs.loadmore.onTopLoaded();
        this.carListData = [];
        this.pageNum = 1;
        this.fetchVehicleList(this.check, this.task);
      } else {
        this.$refs.loadmore.onTopLoaded();
        this.carListData = [];
      }
    },
    loadBottom() {},
    manualWrittenPlate() {
      this.$store.commit("updateCarInfo", {
        plateNumber: "",
      });
       sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      this.$router.push("/car-plate");
    },
    manualWrittenVIN() {
      this.$store.commit("updateCarInfo", {
        VIN: "",
      });
       sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      this.$router.push("/car-vin");
    },
    goToSearch() {
      this.$store.commit("updateCarInfo", {
        inputVal: "",
        haveValue: true,
      });
      this.$router.push("/search");
    },
    option() {
      this.pageNum = 1;
      this.changeBtn = true;
      this.carListData = [];
      if (this.value.length == 0) {
        this.carListData = [];
      }
      if (this.value.length > 0 && this.value.includes("C")) {
        this.check = false;
        this.task = false;
        this.fetchVehicleList(false, false);
        let AIndex = this.value.indexOf("A");
        let BIndex = this.value.indexOf("B");
        if (BIndex < AIndex) {
          AIndex = this.value.indexOf("B");
          BIndex = this.value.indexOf("A");
        }

        if (BIndex >= 0) {
          this.value.splice(BIndex, 1);
        }
        if (AIndex >= 0) {
          this.value.splice(AIndex, 1);
        }
        this.options[0].disabled = true;
        this.options[1].disabled = true;
      } else if (this.value.length > 0 && this.value.includes("A")) {
        if (this.value.includes("A") && this.value.includes("B")) {
          this.check = true;
          this.task = true;
          this.fetchVehicleList(true, true);
        } else {
          this.check = true;
          this.task = false;
          this.fetchVehicleList(true, false);
        }
      } else if (this.value.length > 0 && this.value.includes("B")) {
        if (this.value.includes("A") && this.value.includes("B")) {
          this.check = true;
          this.task = true;
          this.fetchVehicleList(true, true);
        } else {
          this.check = false;
          this.task = true;
          this.fetchVehicleList(false, true);
        }
      }
      if (this.value.length === 0) {
        this.check = false;
        this.task = true;
        this.options[0].disabled = false;
        this.options[1].disabled = false;
      }
    },
    popupVisibleBtn() {
      this.popupVisible = true;
      this.$store.commit("updateCarInfo", {
        plateNumber: "",
        VIN: "",
      });
    },
    async fetchVehicleList(count, assigned) {
      Indicator.open({
        text: "load...",
        spinnerType: "fading-circle",
      });
      let response = await this.$api.vehicle.fetchAllVehicleList(
        this.pageSize,
        this.pageNum,
        count,
        assigned
      );
      if (response) {
        Indicator.close();
        this.hasCreated = false;
        if (this.changeBtn) {
          this.carListData = [];
        }
        this.noVehicleStatus = true;
        this.totalRecordCount = response.totalRecordCount;
        this.carListData = this.carListData.concat(response.data);
        this.changeBtn = false;
      }
      if (
        this.pageSize * this.pageNum >= response.totalRecordCount &&
        response.totalRecordCount != 0
      ) {
        this.allLoaded = true;
      } else if (response.totalRecordCount == 0) {
        this.allLoaded = true;
      } else {
        this.allLoaded = false;
      }
      this.moreLoading = this.allLoaded;
    },
    randomString(e) {
      e = e || 32;
      let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    async toGetSignature(timestamp, nonceStr, url) {
      let response = await this.$api.vehicle.toGetSignature(
        timestamp,
        nonceStr,
        url
      );
      if (response) {
        wx.config({
          beat: true,
          debug: false,
          appId: "ww755fa7ed1d1af941",
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: response,
          jsApiList: ["getLocation", "chooseImage", "ocr"],
        });
      }
    },
  },

  mounted() {
    window.document.title = "Test";
    this.toGetSignature(
      parseInt(Math.round(new Date().getTime() / 1000).toString()),
      this.randomString(16),
      encodeURIComponent(window.location.href.split("#")[0])
    );
  },
  deactivated() {
    this.popupVisible = false;
  },
  activated() {
    sessionStorage.setItem("store", JSON.stringify(""))
    if (this.value.includes("C")) {
      this.options[0].disabled = true;
      this.options[1].disabled = true;
    }
    if (!this.$store.state.information) {
      // this.$store.state.hasStock
      this.carListData = [];
      if (this.hasCreated == false) {
        this.fetchVehicleList(false, true);
        // this.hasCreated = false;
      }
      this.value = ["B"];
      this.noVehicleStatus = false;
      this.popupVisible = false;
      this.popupVisibleFail = false;
      this.check = false;
      this.task = true;
      this.options[0].disabled = false;
      this.options[1].disabled = false;
    } else {
      this.$store.state.information = false;
    }
    wx.onHistoryBack(function () {
      this.$router.replace("/");
    });
    // sessionStorage.setItem("store", "");
  },
  created() {
    sessionStorage.setItem("store", JSON.stringify(""))
    if (!this.$store.state.hasStock) {
      this.carListData = [];
      this.hasCreated = true;
      this.fetchVehicleList(false, true);
    }
    this.$store.commit("updateCarInfo", {
      employeeOrAdmin: "admin",
    });
    remoteLoad("https://res.wx.qq.com/open/js/jweixin-1.2.0.js");
  },
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
.fixed-wrap {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 1000;
}
.bgc-wrap {
  width: 100%;
  height: 0.88rem;
}
.haveCar {
  height: calc(100vh - 1.08rem);
  width: 100%;
  margin: auto;
  font-size: 0.13rem;
  color: #333333;
  line-height: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-wrap {
  height: 0.44rem;
  background-color: white;
  border-bottom: solid 0.01rem #ebebeb;
  display: flex;
  img {
    padding: 0.1rem 0.16rem 0.1rem 0.16rem;
    height: 0.24rem;
    width: 0.24rem;
  }
  input {
    height: 0.26rem;
    width: 78%;
    padding: 0rem;
    border: 0rem;
    margin: auto 0;
    background-color: #f6f6f6;
    border-radius: 0.04rem;
    background-image: url("../../assets/img/icon-search.png");
    background-repeat: no-repeat;
    background-position: 28% 50%;
    outline: none;
  }
  input::-webkit-input-placeholder {
    color: #d8d8d8;
    font-size: 0.12rem;
    padding-left: 34%;
    line-height: 0.17rem;
  }
}
.choose-type {
  height: 0.36rem;
  background-color: white;
  margin-bottom: 0.1rem;
}
.more_loading {
  font-size: 0.13rem;
  display: flex;
  justify-content: center;
  padding-top: 0.05rem;
}
.space-between {
  height: 0.16rem;
  width: 100%;
}
.line-wrap {
  height: 0.1rem;
  background-color: #f6f6f6;
}
.mint-popup {
  .pop-up-title {
    p {
      font-weight: 400;
      line-height: 0.22rem;
      font-size: 0.16rem;
      height: 0.42rem;
    }
    .title-bold {
      font-weight: 600;
    }
  }
}
.vin-num {
  padding: 0.08rem 0.21rem;
  color: #ffffff;
}
.closeBtn {
  margin-top: -0.08rem;
  display: flex;
  border-radius: 0.08rem;
  justify-content: center;
  background-color: #f6f6f6;
  align-content: center;
  line-height: 0.22rem;
  font-size: 0.16rem;
  overflow: hidden;
  .mint-button--normal {
    align-content: center;
    margin-left: 0rem;
    box-shadow: none;
  }
  .mint-button::after {
    background-color: #f6f6f6;
  }
  .mint-button--default {
    margin-left: 0rem !important;
  }
}
.pop-up-wrap {
  p {
    font-size: 0.18rem;
    line-height: 0.25rem;
    font-weight: 600;
    color: #333333;
    display: flex;
    justify-content: center;
  }
  img {
    padding-top: 0.24rem;
    padding-bottom: 0.02rem;
    display: block;
    margin: 0 auto;
  }
  .bottom-btn {
    display: flex;
    padding: 0.24rem 0.23rem 0.2rem 0.22rem;
    .mint-button--primary {
      width: 1.3rem;
      height: 0.4rem;
    }
    .next-btn {
      margin-left: 0.1rem;
      min-width: 1.68rem;
    }
    .try-btn {
      background-color: #ebebeb;
      color: #666666;
    }
  }
}
.spinner-pop-up {
  width: 1rem;
  height: 0.84rem;
  background-color: #000000;
  opacity: 0.5;
  border-radius: 0.04rem;
  position: fixed;
  top: 36%;
  left: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0.2rem;
  p {
    color: #fff;
    font-size: 0.16rem;
    font-weight: 400;
    line-height: 0.22rem;
    padding-top: 0.1rem;
  }
}
.fill-form {
  height: 0.5rem;
}
</style>
