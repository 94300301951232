<template>
  <div class="scanPopup">
    <button @click="goToPop">失败了</button>
    <button @click="goSpinner">加载中</button>
    <button @click="goToToast">成功了</button>
    <mt-popup v-model="popupVisible" popup-transition="popup-fade">
      <!-- 扫描失败popup -->
      <div class="pop-up-wrap">
        <img src="@/assets/img/wrong-sign.png" alt="" />
        <p>{{ $t("lang.ScanFailed") }}</p>
        <div class="bottom-btn">
          <mt-button type="primary" size="normal" class="try-btn">{{
            $t("lang.Retry")
          }}</mt-button>
          <mt-button type="primary" size="normal" class="next-btn">{{
            $t("lang.ManualInput")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
    <!-- 加载中 -->
    <div class="" v-show="spinnerPopupVisible">
      <div class="spinner-pop-up">
        <mt-spinner type="snake" color="#EBEBEB"></mt-spinner>
        <p>扫描中...</p>
      </div>
    </div>

     <!-- 扫码成功Toast -->
  <div >
    <!-- <p>{{ $t("lang.ScanSuccess") }}</p> -->
    <mt-toast type='primary' class="ToastTop"></mt-toast>
  </div>
  </div>
 
</template>

<script>
import {Toast} from "mint-ui"
export default {
  data() {
    return {
      popupVisible: false,
      spinnerPopupVisible: false,
    };
  },
  methods: {
    goToPop() {
      this.popupVisible = true;
    },
    goSpinner() {
      this.spinnerPopupVisible = !this.spinnerPopupVisible;
    },
    goToToast(){
      this.popupVisible = false;
      Toast({
      message: this.$t("lang.ScanSuccess"),
      position: 'top',  
      duration: 3000,
      className:'ToastTop'
});

    },
  },
};

</script>

<style lang="scss" scoped>
.pop-up-wrap {
  p {
    font-size: 0.18rem;
    line-height: 0.25rem;
    font-weight: 600;
    color: #333333;
    padding-left: 1.22rem;
  }
  img {
    padding-top: 0.24rem;
    padding-left: 1.25rem;
  }
  .bottom-btn {
    display: flex;
    padding: 0.24rem 0.23rem 0.2rem 0.22rem;
    .mint-button--primary {
      width: 1.3rem;
      height: 0.4rem;
      font-size: 0.16rem;
    }
    .next-btn {
      margin-left: 0.1rem;
    }
    .try-btn {
      background-color: #ebebeb;
      color: #666666;
    }
  }
}
 
.spinner-pop-up {
  width: 1rem;
  height: 0.84rem;
  background-color: #000000;
  opacity: 0.5;
  border-radius: 0.04rem;
  position: fixed;
  top: 36%;
  left: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 0.2rem;
  
  p {
    color: #fff;
    font-size: 0.16rem;
    font-weight: 400;
    line-height: 0.22rem;
    padding-top: 0.1rem;
  }
}
.mt-toast{
  background-color: #4C9EDD!important;
}
.ToastTop{
  background-color: #4C9EDD!important;
  height: 0.44rem;
  width: 3.75rem;
  font-family: PingFangSC;
  font-size: 0.14rem;
} 
 


</style>
