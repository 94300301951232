<template>
  <div>
    <mt-loadmore :top-method="loadTop" ref="loadmore">
      <div class="carListData-wrap" v-show="this.carListData.length > 0">
        <CarListItem :carListData="carListData"></CarListItem>
      </div>
      <div v-show="carListData == '' && this.noVehicleStatus" class="haveCar">
        {{ $t("lang.NoVehiclesFound") }}
      </div>
    </mt-loadmore>
  </div>
</template>
<script>
import CarListItem from "@/components/car-list-item.vue";
import { Indicator } from "mint-ui";
export default {
  name: "Vehicle-List",
  components: {
    CarListItem,
  },
  data() {
    return {
      carListData: [],
      queryLoading: false,
      moreLoading: false,
      allLoaded: false,
      totalNum: 0,
      noVehicleStatus: false,
    };
  },
  activated() {
    try {
      if (this.$store.state.hasStock) {
        this.carListData = [];
        this.fetchVehicleList();
        this.noVehicleStatus = false;
      }
    } catch (err) {}
    // sessionStorage.setItem("store", "");
  },
  created() {
    if (!this.$store.state.hasStock) {
      this.fetchVehicleList();
    }
  },
  methods: {
    async fetchVehicleList() {
      Indicator.open({
        text: "load...",
        spinnerType: "fading-circle",
      });
      let response = await this.$api.vehicle.fetchVehicleList();
      if (response) {
        Indicator.close();
        this.noVehicleStatus = true;
        this.carListData = response;
      }
    },
    loadTop() {
      this.$refs.loadmore.onTopLoaded();
      this.carListData = [];
      this.fetchVehicleList();
    },
  },
};
</script>
<style lang="scss" scoped>
.more_loading {
  font-size: 0.13rem;
  display: flex;
  justify-content: center;
  padding-top: 0.05rem;
}
.haveCar {
  height: calc(100vh);
  width: 100%;
  margin: auto;
  font-size: 0.13rem;
  color: #333333;
  line-height: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carListData-wrap {
  min-height: 96vh;
}
</style>
