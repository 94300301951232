<template>
  <div class="img-wrap">
    <p>{{ $t("lang.TapToIndicateWhereYourVehicleIsDamaged") }}</p>
    <div class="position-wrap">
      <img src="@/assets/img/carTopView@3x.png" alt="" />
      <ul>
        <li class="front" @click="fontBtn">
          <img
            v-show="!this.front"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.front"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="font-left" @click="fontLeftBtn">
          <img
            v-show="!this.fontLeft"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.fontLeft"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="font-right" @click="fongRightBtn">
          <img
            v-show="!this.fongRight"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.fongRight"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="left-front-door" @click="leftFrontDoorBtn">
          <img
            v-show="!this.leftFrontDoor"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.leftFrontDoor"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="right-front-door" @click="rightFrontDoorBtn">
          <img
            v-show="!this.rightFrontDoor"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.rightFrontDoor"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="left-back-door" @click="leftBackDoorBtn">
          <img
            v-show="!this.leftBackDoor"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.leftBackDoor"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="right-back-door" @click="rightBackDoorBtn">
          <img
            v-show="!this.rightBackDoor"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.rightBackDoor"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="back-left" @click="backLeftBtn">
          <img
            v-show="!this.backLeft"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.backLeft"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="back-right" @click="backRightBtn">
          <img
            v-show="!this.backRight"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.backRight"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
        <li class="back" @click="backBtn">
          <img
            v-show="!this.back"
            src="@/assets/img/btn-damage-default.png"
            alt=""
          />
          <img
            v-show="this.back"
            src="@/assets/img/btn-damage-pressed.png"
            alt=""
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      front: "",
      fontLeft: "",
      fongRight: "",
      leftFrontDoor: "",
      rightFrontDoor: "",
      leftBackDoor: "",
      rightBackDoor: "",
      backLeft: "",
      backRight: "",
      back: "",
      state: false,
      photoShow: true,
      site: "",
    };
  },
  methods: {
    closeOnClickModal() {
      this.$emit("cancle", this.state);
    },
    photoShowFn() {
      this.$emit("photoShow", this.photoShow);
    },
    postSite() {
      this.$emit("siteValue", this.site);
    },
    fontBtn() {
      this.front = "font";
      setTimeout(() => {
        this.closeOnClickModal();
        this.front = "";
        this.photoShowFn();
      }, 500);
      this.site = "FRONT";
      this.postSite();
    },
    fontLeftBtn() {
      this.fontLeft = "fontLeft";
      setTimeout(() => {
        this.closeOnClickModal();
        this.fontLeft = "";
        this.photoShowFn();
      }, 500);
      this.site = "FRONT_LEFT";
      this.postSite();
    },
    fongRightBtn() {
      this.fongRight = "fongRight";
      setTimeout(() => {
        this.closeOnClickModal();
        this.fongRight = "";
        this.photoShowFn();
      }, 500);
      this.site = "FRONT_RIGHT";
      this.postSite();
    },
    leftFrontDoorBtn() {
      this.leftFrontDoor = "leftFrontDoor";
      setTimeout(() => {
        this.closeOnClickModal();
        this.leftFrontDoor = "";
        this.photoShowFn();
      }, 500);
      this.site = "LEFT_FRONT_DOOR";
      this.postSite();
    },
    rightFrontDoorBtn() {
      this.rightFrontDoor = "rightFrontDoor";
      setTimeout(() => {
        this.closeOnClickModal();
        this.rightFrontDoor = "";
        this.photoShowFn();
      }, 500);
      this.site = "RIGHT_FRONT_DOOR";
      this.postSite();
    },
    leftBackDoorBtn() {
      this.leftBackDoor = "leftBackDoor";
      setTimeout(() => {
        this.closeOnClickModal();
        this.leftBackDoor = "";
        this.photoShowFn();
      }, 500);
      this.site = "LEFT_BACK_DOOR";
      this.postSite();
    },
    rightBackDoorBtn() {
      this.rightBackDoor = "rightBackDoor";
      setTimeout(() => {
        this.closeOnClickModal();
        this.rightBackDoor = "";
        this.photoShowFn();
      }, 500);
      this.site = "RIGHT_BACK_DOOR";
      this.postSite();
    },
    backLeftBtn() {
      this.backLeft = "backLeft";
      setTimeout(() => {
        this.closeOnClickModal();
        this.backLeft = "";
        this.photoShowFn();
      }, 500);
      this.site = "BACK_LEFT";
      this.postSite();
    },
    backRightBtn() {
      this.backRight = "backRight";
      setTimeout(() => {
        this.closeOnClickModal();
        this.backRight = "";
        this.photoShowFn();
      }, 500);
      this.site = "BACK_RIGHT";
      this.postSite();
    },
    backBtn() {
      this.back = "back";
      setTimeout(() => {
        this.closeOnClickModal();
        this.back = "";
        this.photoShowFn();
      }, 500);
      this.site = "BACK";
      this.postSite();
    },
  },
};
</script>
<style lang="scss" scoped>
.img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.1rem;
  padding-bottom: 0.34rem;
  .position-wrap {
    position: relative;
    ul {
      li {
        list-style: none;
      }
      .front {
        position: absolute;
        top: -5%;
        left: 45%;
      }
      .font-left {
        position: absolute;
        top: 7%;
        left: 5%;
      }
      .font-right {
        position: absolute;
        top: 7%;
        right: 5%;
      }
      .left-front-door {
        position: absolute;
        top: 36%;
        left: 7%;
      }
      .right-front-door {
        position: absolute;
        top: 36%;
        right: 7%;
      }
      .left-back-door {
        position: absolute;
        top: 57%;
        left: 9%;
      }
      .right-back-door {
        position: absolute;
        top: 57%;
        right: 9%;
      }
      .back-left {
        position: absolute;
        top: 85%;
        left: 13%;
      }
      .back-right {
        position: absolute;
        top: 85%;
        right: 13%;
      }
      .back {
        position: absolute;
        top: 90%;
        left: 45%;
      }
    }
  }

  p {
    padding-bottom: 0.36rem;
    font-size: 0.12rem;
    color: #999999;
  }
}
</style>
