<template>
  <div class="stockTakingTwo">
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information">
        <img src="@/assets/img/icon-list.png" alt="" />
        {{ $t("lang.VehicleSpecificInformation") }}
      </div>
      <li class="info-list main-info-height">
        {{ $t("lang.WarningTriangleInCar") }}
        <mt-radio v-model="trianglePoliceBadge" :options="badgeOptions">
        </mt-radio>
      </li>
      <!-- <li class="info-list main-info-height">
        {{ $t("lang.FireExtinguisher") }}
        <mt-radio v-model="fireExtinguisher" :options="badgeOptions">
        </mt-radio>
      </li>
      <li class="info-list main-info-height">
        {{ $t("lang.FloorMat") }}
        <mt-radio v-model="floorMat" :options="badgeOptions"> </mt-radio>
      </li> -->
    </ul>
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information">
        <img src="@/assets/img/icon-mileage.png" alt="" />
        {{ $t("lang.Mileage") }}
      </div>
      <li class="info-list main-info-height">
        {{ $t("lang.MileageNumber") }}
        <input
          type="text"
          name=""
          id="input-plate-num"
          v-model="mileage"
          oninput="value=value.replace(/[^\d]/g,'')"
          :placeholder="$t('lang.MileageNumberRequired')"
          maxlength="6"
        />
      </li>
      <li class="vertical-alignment">
        {{ $t("lang.OdometerPhoto") }}
        <div class="exterior-photo-wrap">
          <div class="photo-wrap">
            <div class="camera-wrap" @click="exteriorPhotoShow">
              <img
                v-show="!exteriorPhoto"
                src="@/assets/img/icon-camera.png"
                alt=""
              />
              <img
                class="delect-icon"
                v-show="this.exteriorPhoto != ''"
                @click.stop="exteriorPhotoFn"
                src="@/assets/img/icon-cross.png"
                alt=""
              />
              <img
                class="wx-photo"
                v-show="exteriorPhoto"
                :src="exteriorPhoto"
                alt=""
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-info-wrap">
        <div class="Vehicle-basic">
          <img src="@/assets/img/icon-repair.png" alt="" />
          {{ $t("lang.Damages") }}
        </div>
        <p class="hint-info">
          {{ $t("lang.PleaseChooseHowManyPanelsToRepairBelow") }}
        </p>
      </div>

      <li class="info-list main-info-height">
        <ul class="damage-num-wrap">
          <li>
            {{ $t("lang.Perfect") }}
            <div
              class="damage-num-font"
              :class="perfect"
              @click="chooseNofectBtn"
            >
              0
            </div>
          </li>
          <li>
            {{ $t("lang.Minor") }}
            <div class="damage-num-font" :class="minor" @click="chooseMinorBtn">
              1
            </div>
          </li>
          <li>
            {{ $t("lang.Medium") }}
            <div
              class="damage-num-font"
              :class="medium"
              @click="chooseMediumBtn"
            >
              2
            </div>
          </li>
          <li>
            {{ $t("lang.Major") }}
            <div class="damage-num-font" :class="major" @click="chooseMajorBtn">
              2+
            </div>
          </li>
        </ul>
      </li>
      <li class="info-list main-info-height remove-excess">
        <ul>
          <li v-show="this.choosePerfect"></li>
          <li v-show="this.chooseMinor">
            <div class="camera-num-one">
              <div
                class="camera-wrap"
                v-for="(item, index) in damageImgList"
                :key="index"
              >
                <img
                  class="delect-icon"
                  @click="delectPhoto(index)"
                  src="@/assets/img/icon-cross.png"
                  alt=""
                />
                <img class="wx-photo" :src="damageImgList[0]" alt="" />
              </div>
              <div
                class="camera-wrap"
                v-show="!this.damageImgList.length"
                @click="choosePerfectBtn"
              >
                <img src="@/assets/img/icon-camera.png" alt="" />
              </div>
            </div>
            <p class="part-of-photo">
              {{ $t("lang.PleaseAttachOnePhoto") }}
            </p>
          </li>
          <li v-show="this.chooseMedium">
            <div class="camera-num-one">
              <div
                class="camera-wrap"
                v-for="(item, index) in damageImgList"
                :key="index"
              >
                <img
                  class="delect-icon"
                  @click="delectPhoto(index)"
                  src="@/assets/img/icon-cross.png"
                  alt=""
                />
                <img class="wx-photo" :src="item" alt="" />
              </div>
              <div
                class="camera-wrap"
                v-show="this.damageImgList.length < 2"
                @click="choosePerfectBtn"
              >
                <img src="@/assets/img/icon-camera.png" alt="" />
              </div>
              <div
                class="camera-wrap"
                v-show="!this.damageImgList.length"
                @click="choosePerfectBtn"
              >
                <img src="@/assets/img/icon-camera.png" alt="" />
              </div>
            </div>
            <p class="part-of-photo">
              {{ $t("lang.PleaseAttachTwoPhoto") }}
            </p>
          </li>
          <li v-show="this.chooseMajor">
            <div class="camera-num-one flex-wrap">
              <div
                class="camera-wrap"
                v-for="(item, index) in damageImgList"
                :key="index"
              >
                <img
                  class="delect-icon"
                  @click="delectPhoto(index)"
                  src="@/assets/img/icon-cross.png"
                  alt=""
                />
                <img class="wx-photo" :src="item" alt="" />
              </div>
              <div
                class="camera-wrap"
                v-show="this.damageImgList.length < 6"
                @click="choosePerfectBtn"
              >
                <img src="@/assets/img/icon-camera.png" alt="" />
              </div>
              <div
                class="camera-wrap"
                v-show="this.damageImgList.length < 2"
                @click="choosePerfectBtn"
              >
                <img src="@/assets/img/icon-camera.png" alt="" />
              </div>
              <div
                class="camera-wrap"
                v-show="this.damageImgList.length < 1"
                @click="choosePerfectBtn"
              >
                <img src="@/assets/img/icon-camera.png" alt="" />
              </div>
            </div>
            <p class="part-of-photo">
              {{ $t("lang.PleaseAttachMoreThanThreePhoto") }}
            </p>
          </li>
        </ul>
      </li>
      <li class="info-list main-info-height">
        {{ $t("lang.carIsMobileAndInUse") }}
        <mt-radio v-model="carIsMobileAndInUse" :options="badgeOptions">
        </mt-radio>
      </li>
    </ul>
    <div class="stock-taking-next-btn">
      <mt-button
        size="large"
        class="stock-taking-btn"
        :disabled="this.disabled"
        :class="toggleType"
        @click="nextStep"
        >{{ $t("lang.Next") }}</mt-button
      >
    </div>
    <mt-popup v-model="popupVisible" position="bottom">
      <div class="damage-body-title">
        <p>{{ $t("lang.DamageIndication") }}</p>
      </div>
      <div>
        <DamagesConstants
          @cancle="cancleBtn"
          @photoShow="photoShow"
          @siteValue="siteValue"
        ></DamagesConstants>
      </div>
    </mt-popup>
  </div>
</template>
<script>
import DamagesConstants from "./DamagesConstants.vue";
import remoteLoad from "../../assets/remoteload";

export default {
  name: "stock-taking-two",
  components: {
    DamagesConstants,
  },
  data() {
    return {
      popupVisible: false,
      choosePerfect: this.$store.state.choosePerfect,
      chooseMinor: this.$store.state.chooseMinor,
      chooseMedium: this.$store.state.chooseMedium,
      chooseMajor: this.$store.state.chooseMajor,
      site: "",
      cameraShow: false,
      badgeOptions: [
        {
          label: this.$t("lang.Yes"),
          value: this.$t("lang.Yes"),
        },
        {
          label: this.$t("lang.No"),
          value: this.$t("lang.No"),
        },
      ],

      trianglePoliceBadge: this.$store.state.warningTriangleInCar,
      floorMat: this.$store.state.floorMat,
      fireExtinguisher: this.$store.state.fireExtinguisher,
      carIsMobileAndInUse: this.$store.state.carIsMobileAndInUse,
      disabled: true,
      mileage: this.$store.state.mileage,
      injuryDegree: this.$store.state.injuryDegree,
      exteriorPhoto: this.$store.state.exteriorPhoto,
      photoList: [],
      damageImgList: this.$store.state.damageImgList,
      siteList: [],
      needPhoto: false,
    };
  },
  mounted() {
    this.toGetSignature(
      parseInt(Math.round(new Date().getTime() / 1000).toString()),
      this.randomString(16),
      encodeURIComponent(window.location.href.split("#")[0])
    );
    let obj = this.$store.state.photoList;
    if (!this.$store.state.photoList) {
      this.photoList = [];
    } else {
      this.photoList = obj;
    }
  },
  computed: {
    perfect() {
      if (this.choosePerfect) {
        this.injuryDegree = this.$t("lang.Perfect");
        return "perfect";
      }
    },
    minor() {
      if (this.chooseMinor) {
        this.injuryDegree = this.$t("lang.Minor");
        return "minor";
      }
    },
    medium() {
      if (this.chooseMedium) {
        this.injuryDegree = this.$t("lang.Medium");
        return "medium";
      }
    },
    major() {
      if (this.chooseMajor) {
        this.injuryDegree = this.$t("lang.Major");
        return "major";
      }
    },
    toggleType() {
      if (
        this.mileage &&
        this.injuryDegree &&
        this.exteriorPhoto &&
        (this.choosePerfect || this.damageImgList.length) &&
        this.trianglePoliceBadge &&
        // this.fireExtinguisher &&
        // this.floorMat &&
        this.carIsMobileAndInUse
      ) {
        if (
          this.choosePerfect ||
          (this.chooseMinor && this.damageImgList.length == 1) ||
          (this.chooseMedium && this.damageImgList.length == 2) ||
          (this.chooseMajor && this.damageImgList.length >= 3)
        ) {
          this.disabled = false;
          return "verifyBtn";
        }
      }
    },
  },
  created() {
    // if (sessionStorage.getItem('store')) {
    //   this.$store.replaceState(
    //           Object.assign(
    //                   {},
    //                   this.$store.state,
    //                   JSON.parse(sessionStorage.getItem('store'))
    //           )
    //   )
    // }
    remoteLoad("https://res.wx.qq.com/open/js/jweixin-1.2.0.js");
    if (
      this.choosePerfect ||
      this.chooseMinor ||
      this.chooseMedium ||
      this.chooseMajor
    ) {
    } else {
      this.$store.state.damageImgList = [];
    }
    if (!this.$store.state.carModel) {
      this.$router.go(-1);
    }
  },
  watch: {
    cameraShow(msg) {
      if (msg) {
        this.cameraFn();
      }
      this.cameraShow = "";
    },
  },
  methods: {
    randomString(e) {
      e = e || 32;
      let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    async toGetSignature(timestamp, nonceStr, url) {
      let response = await this.$api.vehicle.toGetSignature(
        timestamp,
        nonceStr,
        url
      );
      if (response) {
        wx.config({
          beat: true,
          debug: false,
          appId: "ww755fa7ed1d1af941",
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: response,
          jsApiList: ["getLocation", "chooseImage"],
        });
      }
    },
    exteriorPhotoShow() {
      let that = this;
      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["camera"],
        success(res) {
          wx.getLocalImgData({
            localId: res.localIds[0],
            success(ew) {
              if (ew.localData.indexOf("data:image") == 0) {
                that.exteriorPhoto = ew.localData;
              } else {
                that.exteriorPhoto =
                  "data:image/jpeg;base64," + ew.localData.replace(/\n/g, "");
              }
            },
          });
        },
      });
    },
    cancleBtn(msg) {
      this.popupVisible = msg;
    },
    photoShow(msg) {
      this.cameraShow = msg;
    },
    siteValue(msg) {
      this.site = msg;
    },
    cameraFn() {
      let that = this;
      var site = this.site;
      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["camera"],
        success(res) {
          wx.getLocalImgData({
            localId: res.localIds[0],
            success(ew) {
              if (ew.localData.indexOf("data:image") == 0) {
                that.photoList.push({ site: site, img: ew.localData });
                that.siteList.push(this.site);
                that.damageImgList.push(ew.localData);
              } else {
                that.photoList.push({
                  site: site,
                  img:
                    "data:image/jpeg;base64," + ew.localData.replace(/\n/g, ""),
                });
                that.damageImgList.push(ew.localData);
                this.cameraShow = false;
              }
            },
          });
        },
      });
    },
    choosePerfectBtn() {
      this.popupVisible = true;
    },
    delectPhoto(index) {
      if (this.photoList.length != 0) {
        this.photoList.splice(index, 1);
      }
      if (this.damageImgList.length != 0) {
        this.damageImgList.splice(index, 1);
      }
    },
    exteriorPhotoFn() {
      this.exteriorPhoto = "";
    },
    chooseNofectBtn() {
      this.choosePerfect = "choose";
      this.chooseMinor = false;
      this.chooseMedium = false;
      this.chooseMajor = false;
      if (this.photoList.length != 0) {
        this.photoList.splice(0);
      }
      if (this.damageImgList.length != 0) {
        this.damageImgList.splice(0);
      }
    },
    chooseMinorBtn() {
      this.choosePerfect = false;
      this.chooseMinor = true;
      this.chooseMedium = false;
      this.chooseMajor = false;
      if (this.photoList.length != 0) {
        this.photoList.splice(1);
      }
      if (this.damageImgList.length != 0) {
        this.damageImgList.splice(1);
      }
      this.needPhoto = true;
    },
    chooseMediumBtn() {
      this.choosePerfect = false;
      this.chooseMinor = false;
      this.chooseMedium = true;
      this.chooseMajor = false;
      if (this.photoList.length != 0) {
        this.photoList.splice(2);
        this.damageImgList.splice(2);
      }
      if (this.damageImgList.length != 0) {
        this.damageImgList.splice(2);
      }
      this.needPhoto = true;
    },
    chooseMajorBtn() {
      this.choosePerfect = false;
      this.chooseMinor = false;
      this.chooseMedium = false;
      this.chooseMajor = true;
      this.needPhoto = true;
    },
    async nextStep() {
      await this.$store.commit("inventoryInformation", {
        warningTriangleInCar: this.trianglePoliceBadge,
        fireExtinguisher: this.fireExtinguisher,
        floorMat: this.floorMat,
        mileage: this.mileage,
        carIsMobileAndInUse: this.carIsMobileAndInUse,
        damages: this.injuryDegree,
        exteriorPhoto: this.exteriorPhoto,
        photoList: this.photoList,
        choosePerfect: this.choosePerfect,
        chooseMinor: this.chooseMinor,
        chooseMedium: this.chooseMedium,
        chooseMajor: this.chooseMajor,
        damageImgList: this.damageImgList,
      });
      //  sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      Promise.resolve().then(() => this.$router.push("/stockTakingSummary"));
    },
  },
};
</script>
<style scoped lang="scss">
.stockTakingTwo {
  height: 100vh;
  .info-list-wrap {
    margin-bottom: 0.1rem;
    .Vehicle-basic-information {
      height: 0.52rem;
      font-size: 0.16rem;
      line-height: 0.22rem;
      color: #333333;
      font-weight: 600;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #ebebeb;
      align-items: center;
      display: flex;
      padding-left: 0.2rem;

      img {
        padding-right: 0.08rem;
      }
    }
    .Vehicle-basic-info-wrap {
      font-size: 0.16rem;
      line-height: 0.22rem;
      color: #333333;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #ebebeb;
      padding-left: 0.2rem;
      .Vehicle-basic {
        display: flex;
        height: 0.38rem;
        align-items: center;
        font-weight: 600;
      }

      .hint-info {
        font-size: 0.12rem;
        color: #999999;
        line-height: 0.17rem;
        background-color: #ffffff;
        padding-left: 0.3rem;
        padding-bottom: 0.12rem;
      }
    }
    .info-list {
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: #333333;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #f6f6f6;
      align-items: center;
      display: flex;
      justify-content: space-between;
      .mint-radiolist {
        display: flex;
      }
      #input-plate-num {
        border-width: 0;
        outline: none;
        padding-right: 0.26rem;
        padding-left: 0.1rem;
        border-left: solid 0.01rem #ebebeb;
        color: #999999;
        font-size: 0.13rem;
        width: 1.75rem;
      }
      .photo-wrap {
        display: flex;
      }
      .damage-num-wrap {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-right: 0.2rem;
        display: flex;
        li {
          min-width: 0.28rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.12rem;
          color: #999999;
          .damage-num-font {
            width: 0.24rem;
            height: 0.24rem;
            border-radius: 50%;
            background-color: #ebebeb;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.14rem;
            color: #999999;
            margin-top: 0.06rem;
          }
        }
      }
      .camera-num-one {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    .main-info-height {
      padding: 0.19rem 0rem 0.19rem 0.2rem;
    }
    .vertical-alignment {
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: #333333;
      background-color: #ffffff;
      border-bottom: solid 0.01rem #f6f6f6;
      display: flex;
      flex-direction: column;
      padding: 0.13rem 0rem 0.13rem 0.2rem;
    }
  }
}
.perfect {
  background-color: #4c9edd !important;
  color: #ffffff !important;
}
.minor {
  background-color: #4c9edd !important;
  color: #ffffff !important;
}
.medium {
  background-color: #4c9edd !important;
  color: #ffffff !important;
}
.major {
  background-color: #4c9edd !important;
  color: #ffffff !important;
}
li {
  list-style: none;
}
.camera-wrap {
  width: 0.8rem;
  height: 0.8rem;
  border: dashed 0.01rem #d8d8d8;
  margin: 0.1rem 0.2rem 0rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .wx-photo {
    width: 0.8rem;
    height: 0.8rem;
  }
  .delect-icon {
    position: absolute;
    z-index: 1000;
    width: 0.16rem;
    height: 0.16rem;
    top: 0.03rem;
    right: 0.03rem;
  }
  .flex-wrap {
    display: flex;
  }
  img {
    width: 0.33rem;
    height: 0.29rem;
  }
}
.part-of-photo {
  font-size: 0.12rem;
  color: #999999;
  line-height: 0.17rem;
  background-color: #ffffff;
  padding-bottom: 0.12rem;
  padding-top: 0.09rem;
}
.remove-excess {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.bgc-wrap {
  height: 0.4rem;
  width: 100%;
}
.mint-popup-bottom {
  flex-direction: column;
  width: 100%;
  .damage-body-title {
    width: 100%;
    background-color: #ffffff;
    p {
      font-size: 0.16rem;
      font-weight: 600;
      color: #333333;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }
}
</style>
