import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCropper from "vue-cropper";
import VueWechatTitle from "vue-wechat-title";
import vueEsign from "vue-esign";
import api from "./service/apiconfig/";
import {
  Picker,
  Button,
  Header,
  Navbar,
  TabItem,
  TabContainer,
  TabContainerItem,
  Cell,
  Search,
  Checklist,
  Spinner,
  Popup,
  InfiniteScroll,
  Radio,
  Switch,
} from "mint-ui";
import "mint-ui/lib/style.css";
import "./styles/weui.scss";
import VueI18n from "vue-i18n";
import "./styles/init.css";
import "./utils/rem.js";
import Mint from "mint-ui";
import moment from "moment";
import { langEn } from "./vueI18n/language-en";
import { langZh } from "./vueI18n/language-zh";
// import VConsole from 'vconsole';
import _ from 'lodash'

Vue.use(VueAxios, axios);
Vue.use(VueI18n);
Vue.use(Mint);
Vue.use(VueCropper);
Vue.use(VueWechatTitle);
Vue.use(vueEsign);
Vue.use(InfiniteScroll);
Vue.component(Navbar.name, Navbar);
Vue.component(TabItem.name, TabItem);
Vue.component(Button.name, Button);
Vue.component(Header.name, Header);
Vue.component(TabContainer.name, TabContainer);
Vue.component(TabContainerItem.name, TabContainerItem);
Vue.component(Cell.name, Cell);
Vue.component(Picker.name, Picker);
Vue.component(Search.name, Search);
Vue.component(Checklist.name, Checklist);
Vue.component(Popup.name, Popup);
Vue.component(Spinner.name, Spinner);
Vue.component(Radio.name, Radio);
Vue.component(Switch.name, Switch);
Vue.prototype.$api = api;
Vue.prototype._ = _
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
axios.defaults.withCredentials = true;

// const vConsole = new VConsole();
var lang = ""
if ((navigator.language == 'zh-cn') || (navigator.language == 'zh-CN')) {
  lang = 'zh'
} else if ((navigator.language == 'en-us') || (navigator.language == 'en-US')) {
  lang = 'en'
}
const i18n = new VueI18n({
  locale: lang,
  messages: {
    zh: { lang: langZh },
    en: { lang: langEn },
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
