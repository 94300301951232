<template>
  <div class="list-wrap">
    <li
      v-for="(item, index) in carListData"
      v-bind:key="index"
      @click="goToDetail(item.id)"
      class="list-item"
    >
      <div class="car-pho">
        <img :src="fetImage(item.model)" alt="" />
      </div>
      <div class="car-info">
        <div class="car-license-info">
          <div class="license-information">
            {{ item.plateNo ? item.plateNo : item.legacyPlateNo }}
          </div>
          <div class="task" v-if="item.stockStatus == 'TASK'">
            {{ $t("lang.Task") }}
          </div>
          <div class="hasTask" v-else-if="item.stockStatus == 'DONE'">
            {{ $t("lang.TaskDone") }}
          </div>
          <div class="submitTask" v-else-if="item.stockStatus == 'SUBMIT'">
            {{ $t("lang.SUBMIT") }}
          </div>
        </div>
        <div class="car-type">{{ item.model }}</div>
        <span class="car-tag">{{ item.usage }}</span>
      </div>
    </li>
  </div>
</template>
<script>
import ImageMapper from "../utils/CarImageMapper";
export default {
  name: "car-list-item",
  data() {
    return {};
  },
  props: {
    carListData: {
      type: Array,
    },
  },
  methods: {
    goToDetail(id) {
      this.$router.push(`/vehicle-information/${id}`);
    },

    fetImage(model) {
      return ImageMapper(model);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.list-wrap{
  min-height: 100vh;
}
.list-item {
  width: 100%;
  background-color: white;
  display: flex;
  margin-bottom: 0.08rem;
  // min-height: 100vh;
  .car-pho {
    margin: auto;
    padding: 0 0.16rem;
    img {
      width: 1.44rem;
    }
  }
  .car-info {
    width: 100%;
    .car-license-info {
      padding-top: 0.14rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .license-information {
        border: 1px solid #d8d8d8;
        padding: 0rem 0.04rem;
        height: 0.24rem;
        font-size: 0.16rem;
        line-height: 0.23rem;
        font-weight: 600;
        background-color: #ebebeb;
        text-align: center;
      }
      .task {
        width: 0.62rem;
        font-size: 0.1rem;
        height: 0.16rem;
        // line-height: 0.16rem;
        display: flex;
        align-items: center;
        background-color: #4c9edd;
        color: #ffffff;
        text-align: center;
        justify-content: center;
      }
      .hasTask {
        width: 0.62rem;
        font-size: 0.1rem;
        height: 0.16rem;
        // line-height: 0.16rem;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-align: center;
        justify-content: center;
        background-color: #07c160;
      }
      .submitTask {
        width: 0.62rem;
        font-size: 0.1rem;
        height: 0.16rem;
        // line-height: 0.16rem;
        display: flex;
        align-items: center;
        background-color: #d8d8d8;
        text-align: center;
        justify-content: center;
      }
    }
  }
  .car-type {
    width: 1.76rem;
    padding: 0.06rem 0rem 0.06rem 0rem;
    font-size: 0.13rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.18rem;
  }

  .car-tag {
    float: left;
    padding: 0rem 0.08rem;
    height: 0.18rem;
    background: #4a5b70;
    color: #ffffff;
    display: flex;
    align-content: center;
    border-radius: 0.08rem;
    font-size: 0.1rem;
    font-weight: 400;
    line-height: 0.17rem;
  }
}
</style>
