import { render, staticRenderFns } from "./stockTakingSummary.vue?vue&type=template&id=400af61a&scoped=true"
import script from "./stockTakingSummary.vue?vue&type=script&lang=js"
export * from "./stockTakingSummary.vue?vue&type=script&lang=js"
import style0 from "./stockTakingSummary.vue?vue&type=style&index=0&id=400af61a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400af61a",
  null
  
)

export default component.exports