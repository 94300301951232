<template>
  <div>
    <div>初始页面</div>
    <div>{{ this.result }}</div>
  </div>
</template>
<script>
import { getCookie } from "../../utils/CookieManager";

export default {
  data() {
    return {
      result: [],
    };
  },
  created() {
    var code = window.location.href.split("?")[1].split("=")[1];
    var uuid = getCookie("uuid");
    this.sendCode(code, uuid);
  },
  methods: {
    async sendCode(code, uuid) {
      let response = await this.$api.vehicle.sendCode(code, uuid);
      if (response) {
        this.result = response;
      }
    },
  },
};
</script>
<style scoped></style>
