export const langEn = {
  Title: "Vehicle List",
  VehicleInformation: "Vehicle Information",
  CarPlateDetail: "Car Plate Detail",
  CarVINDetail: "Car VIN Detail",
  StockTakingOne: "Stock Taking (1/2)",
  StockTakingTwo: "Stock Taking (2/2)",
  StockTaking: "Stock Taking",
  Task: "TASK",
  SUBMIT: "SUBMITTED",
  TaskDone: "DONE",
  Counted: "Counted",
  Reference: "*Reference Image",
  Vehicle: "Vehicle",
  Related: "Related",
  Violation: "Violation",
  PlateNumber: "Plate Number",
  VIN: "VIN",
  CarModel: "Car Model",
  EngineNo: "Engine No",
  RegisteredCity: "Registered City",
  RegisteredCompany: "Registered Company",
  RegisterationDate: "Registeration Date",
  User: "User",
  Email: "Email",
  Telephone: "Telephone",
  Mobile: "Mobile",
  Company: "Company",
  InsuranceCompany: "Insurance Company",
  ClientService: "Client Service",
  Type: "Type",
  Place: "Place",
  Detail: "Detail",
  TakeStock: "Take Stock",
  InputCarPlateInformation: "Input car plate information",
  InputCarVINInformation: "Input car VIN information",
  CarPlateNo: "Car Plate No",
  Next: "Next",
  NoVehiclesFound: "No Vehicles Found",
  ChooseScanType: "Choose Scan Type",
  ScanCarPlate: "Scan Car Plate",
  ManualInput: "Manual Input",
  CameraScan: "Camera Scan",
  ScanVINCode: "Scan VIN Code",
  noPlateNumber: "No Plate",
  SearchByN: "Search by plate or VIN",
  Close: "Close",
  NoViolationRecord: "No violation record",
  ScanFailed: "Scan failed",
  ScanSuccess:"Scan Successful",
  Retry: "Retry",
  AssignedToMe: "Assigned to me",
  All: "All",
  Cancel: "Cancel",
  VehicleBasicInformation: "Vehicle Basic Information",
  Location: "Location",
  ExteriorColour: "Exterior Colour",
  MyLocation: "My Location",
  InteriorPhoto: "Interior Colour",
  Required: "Required",
  ExteriorPhoto: "Exterior Photo",
  PleaseTakePhotos:
    "Please take photos of the entire car, with clear plate number",
  LeftFront: "Left-front",
  RightBack: "Right-back",
  VehicleLicense: "Vehicle License",
  TapHereToAddPhoto: "Tap here to add photo of your license",
  White: "White",
  Silver: "Silver",
  Grey: "Grey",
  Blue: "Blue",
  Green: "Green",
  Red: "Red",
  Yellow: "Yellow",
  Orange: "Orange",
  Black: "Black",
  Brown: "Brown",
  Beige: "Beige",
  Other: "Other",
  VehicleSpecificInformation: "Vehicle Specific Information",
  WarningTriangleInCar: "Warning Triangle In Car",
  FireExtinguisher: "Fire Extinguisher",
  FloorMat: "Floor Mat",
  Mileage: "Mileage",
  MileageNumber: "Mileage Number",
  MileageNumberRequired: "Mileage Number Required",
  OdometerPhoto: "Odometer Photo",
  Damages: "Damages",
  PleaseChooseHowManyPanelsToRepairBelow:
    "Please choose how many panels to repair below",
  Perfect: "Perfect",
  Minor: "Minor",
  Medium: "Medium",
  Major: "Major",
  carIsMobileAndInUse: "Car Is Mobile and In Use",
  PleaseAttachOnePhoto: "Please attach 1 photos of damage parts",
  PleaseAttachTwoPhoto: "Please attach 2 photos of damage parts",
  PleaseAttachMoreThanThreePhoto:
    "Please attach at least 3 photos of damage parts",
  MyLocationObtained: "My Location Obtained",
  DamageLevel: "Damage Level",
  NewEnergyVehicle: "New Energy Vehicle",
  DigitalSignature: "Digital Signature",
  PleaseSignYourNameBelow: "Please sign your name below",
  Clear: "Clear",
  CheckPlateNumber: "Cannot take stock, please check plate number",
  CheckPlateVIN: "Cannot take stock, please check VIN",
  Tosubmit: "Submit",
  Success: "Success",
  TipOfSuccess:
    "We need 1-3 days to verify the stock taking, you can check the result in Vehicle List.",
  Ikonw: "OK",
  PageUnavailable: "Page Unavailable",
  LoginIssues: "If you have login issues, please contact ",
  ContactCompanycarstocktaking: "companycarstocktaking@daimler.com",
  Employee: "Employee",
  FleetAdmin: "Fleet Admin",
  Yes: "YES",
  No: "NO",
  ChooseRole: "Please choose your role",
  DamageIndication: "Damage Indication",
  TapToIndicateWhereYourVehicleIsDamaged:
    "Tap to indicate where your vehicle is damaged",
  DamagePhoto: "Damage Photo",
  VehicleButler: "Vehicle Butler"

};
