<template>
  <div>{{ this.result }}</div>
</template>
<script>
import { Indicator } from "mint-ui";
export default {
  data() {
    return {
      inter: null,
      roleList: [],
      result: [],
    };
  },
  created() {
    this.init();
    setTimeout(() => {
      this.initList();
    }, 1000);
    Indicator.open({
      text: "页面加载中...",
      spinnerType: "fading-circle",
    });
  },
  beforeDestroy() {
    clearInterval(this.inter);
    Indicator.close();
  },
  methods: {
    async init() {
      await this.$api.vehicle.init();
    },
    initList() {
      this.inter = setInterval(() => {
        var uuid = localStorage.getItem("uuid");
        this.getRoleInfo(uuid);
      }, 500);
    },
    async getRoleInfo(key) {
      let response = await this.$api.vehicle.getRoleInfo(key);
      if (response) {
        this.result = response;
        clearInterval(this.inter);
        response.forEach((e) => {
          this.roleList.push(e.role);
        });
        if (response.status_code == 200) {
        } else if (response.status_code == (401 || 40029)) {
          this.$router.push("/error");
        }
      }
    },
  },
};
</script>
<style scoped></style>
